<script src="./index.js"></script>
<style src="./style.scss" lang="scss" scoped/>
<template>
  <div id="main-container">
    <div class="heading-container row  justify-content-center">
      <div class="svg-container row g-0">
        <div class="svg-mobile d-md-none">
          <svg width="239" height="156" viewBox="0 0 239 156" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_225_25636)">
              <path
                d="M152.971 19.1252C152.739 19.1252 152.6 19.1097 152.585 19.1097L152.6 18.9555C152.631 18.9555 155.517 19.2177 157.785 17.1973L157.878 17.3052C156.072 18.9555 153.85 19.1252 152.971 19.1252Z"
                fill="#7FC064"/>
              <path
                d="M156.32 20.8365C155.301 20.6823 154.653 20.2196 154.283 19.8495C153.897 19.4485 153.743 19.0937 153.743 19.0783L153.882 19.0166C153.882 19.032 154.468 20.4047 156.351 20.6823L156.32 20.8365Z"
                fill="#7FC064"/>
              <path
                d="M107.958 40.8866H102.557L101.986 19.3248C101.986 19.3248 102.804 18.9238 104.1 18.8005C105.844 18.6308 107.418 18.7079 107.418 18.7079L107.958 40.8866Z"
                fill="#F4F4F4"/>
              <path d="M121.831 40.8866H107.959L107.434 18.708L121.831 19.3249V40.8866Z" fill="#7FC064"/>
              <path
                d="M116.799 40.8873H112.756V14.1433C112.756 14.1433 113.589 13.912 114.7 13.8194C115.919 13.7269 117.478 13.8194 117.478 13.8194L116.799 40.8873Z"
                fill="#F4F4F4"/>
              <path d="M127.154 40.8869H116.8L117.479 13.8037L127.154 14.143V40.8869Z" fill="#7FC064"/>
              <path
                d="M126.476 40.8873H123.436L119.208 20.7445C119.208 20.7445 119.717 20.6057 120.504 20.5749C121.399 20.5286 122.248 20.7445 122.248 20.7445L126.476 40.8873Z"
                fill="#F4F4F4"/>
              <path d="M134.268 40.8869H126.475L122.263 20.7441L129.978 21.87L134.268 40.8869Z" fill="#00A76E"/>
              <path
                d="M102.078 22.1636L102.047 22.0248C102.063 22.0248 103.22 21.7163 104.47 21.7934C105.705 21.8705 107.387 21.4541 107.402 21.4541L107.433 21.5929C107.417 21.5929 105.705 22.0093 104.455 21.9322C103.22 21.8705 102.094 22.1636 102.078 22.1636Z"
                fill="#7FC064"/>
              <path
                d="M112.771 16.2565L112.756 16.1177C112.787 16.1177 115.595 15.7013 117.447 16.1177L117.416 16.2565C115.58 15.8401 112.787 16.2565 112.771 16.2565Z"
                fill="#7FC064"/>
              <path
                d="M117.339 17.6902C115.827 17.0115 112.787 17.2583 112.756 17.2583L112.741 17.1195C112.864 17.1041 115.842 16.8573 117.401 17.5513L117.339 17.6902Z"
                fill="#7FC064"/>
              <path
                d="M112.771 37.5403L112.756 37.4015C112.864 37.386 115.549 36.9696 116.907 37.4015L116.861 37.5403C115.518 37.1084 112.802 37.5403 112.771 37.5403Z"
                fill="#7FC064"/>
              <path
                d="M112.771 38.2808L112.756 38.142C112.787 38.142 115.364 37.8027 116.891 38.142L116.861 38.2808C115.348 37.9415 112.787 38.2654 112.771 38.2808Z"
                fill="#7FC064"/>
              <path
                d="M119.7 23.0578L119.669 22.919C119.746 22.9036 121.629 22.5026 122.755 22.919L122.709 23.0578C121.629 22.6568 119.715 23.0578 119.7 23.0578Z"
                fill="#00A76E"/>
              <path
                d="M119.824 23.6749L119.793 23.5361C119.871 23.5207 121.599 23.2276 122.849 23.5361L122.818 23.6749C121.599 23.3664 119.84 23.6749 119.824 23.6749Z"
                fill="#00A76E"/>
              <path
                d="M122.694 37.3399L122.664 37.2011C122.741 37.1857 124.531 36.8927 125.734 37.2011L125.704 37.3399C124.515 37.0469 122.71 37.3399 122.694 37.3399Z"
                fill="#00A76E"/>
              <path
                d="M125.858 38.1265C124.33 37.8026 122.864 38.1265 122.849 38.1265L122.818 37.9877C122.833 37.9877 124.33 37.6484 125.889 37.9877L125.858 38.1265Z"
                fill="#00A76E"/>
              <path
                d="M154.436 35.9976C154.436 35.9976 154.344 36.2598 154.128 36.6763C154.128 36.6917 154.112 36.7071 154.112 36.7071C153.742 37.4474 153.017 38.6659 151.859 39.9151C151.474 40.347 150.933 40.6709 150.316 40.9177H150.301C150.162 40.9639 150.023 41.0256 149.884 41.0565C147.878 41.6888 145.116 41.5654 142.94 40.8714L142.57 40.748C142.385 40.6863 142.199 40.6092 142.03 40.5321C141.474 40.2853 141.011 39.9768 140.641 39.6375C140.594 39.5913 140.548 39.545 140.502 39.4987C139.005 38.0027 139.021 35.9668 139.021 35.9668H154.436V35.9976Z"
                fill="#4DB05B"/>
              <path
                d="M154.946 32.9284V35.1494C154.946 36.522 151.227 37.6325 146.644 37.6325C142.061 37.6325 138.342 36.522 138.342 35.1494V32.9284C138.342 32.2344 140.487 31.6329 143.511 31.3861C144.483 31.309 145.533 31.2627 146.644 31.2627C151.227 31.2627 154.946 32.0184 154.946 32.9284Z"
                fill="#7FC064"/>
              <path
                d="M154.946 32.9284C154.946 33.0672 154.869 33.1906 154.714 33.314C153.85 34.0389 150.563 34.5941 146.644 34.5941C145.548 34.5941 144.499 34.5478 143.542 34.4707C140.502 34.224 138.342 33.6225 138.342 32.9284C138.342 32.2344 140.487 31.6329 143.511 31.3861C144.483 31.309 145.533 31.2627 146.644 31.2627C151.227 31.2627 154.946 32.0184 154.946 32.9284Z"
                fill="#00A76E"/>
              <path
                d="M147.137 9.9477C146.335 10.4721 145.949 10.3487 145.779 9.99397C145.594 9.63923 145.687 9.20738 146.474 8.68299C147.924 7.72675 148.958 7.8964 148.958 7.8964C148.958 7.8964 148.603 8.97603 147.137 9.9477Z"
                fill="#7FC064"/>
              <path
                d="M147.091 16.6573C146.489 17.4439 146.088 17.4593 145.81 17.1971C145.532 16.9349 145.486 16.4877 146.088 15.7011C147.183 14.2667 148.217 14.0508 148.217 14.0508C148.217 14.0508 148.202 15.2075 147.091 16.6573Z"
                fill="#7FC064"/>
              <path
                d="M146.722 34.1318L146.552 34.1163C146.552 34.0855 146.768 31.0471 145.873 26.6052C145.04 22.5026 143.034 16.3333 138.281 10.2103L138.42 10.0869C143.203 16.2408 145.209 22.4564 146.058 26.5744C146.953 31.0471 146.737 34.1009 146.722 34.1318Z"
                fill="#7FC064"/>
              <path
                d="M143.094 17.8143L142.94 17.7063C146.366 11.7067 144.298 4.4269 144.282 4.34978L144.452 4.28809C144.468 4.3652 146.551 11.7375 143.094 17.8143Z"
                fill="#7FC064"/>
              <path
                d="M140.456 14.637C138.682 14.637 137.339 13.6345 137.324 13.6345L137.416 13.4648C137.432 13.4803 139.16 14.745 141.259 14.3748L141.29 14.5753C140.996 14.6062 140.719 14.637 140.456 14.637Z"
                fill="#7FC064"/>
              <path
                d="M146.428 29.7054C142.832 29.2427 139.931 27.284 137.786 23.8754C137.709 23.752 137.648 23.6441 137.617 23.6286C137.617 23.6286 137.617 23.6286 137.632 23.6441L137.694 23.459C137.74 23.4744 137.771 23.5207 137.925 23.7674C140.039 27.1297 142.91 29.073 146.459 29.5203L146.428 29.7054Z"
                fill="#7FC064"/>
              <path
                d="M141.351 27.5926L141.182 27.5463C141.799 24.9706 140.271 21.2074 140.256 21.1611L140.41 21.084C140.41 21.0994 140.811 22.0557 141.12 23.3358C141.413 24.5234 141.691 26.2199 141.351 27.5926Z"
                fill="#7FC064"/>
              <path
                d="M138.157 9.1148C139.083 10.0556 139.16 10.58 138.913 10.8731C138.666 11.1815 138.203 11.1352 137.277 10.2099C135.595 8.49787 135.209 7.14062 135.209 7.14062C135.209 7.14062 136.444 7.3874 138.157 9.1148Z"
                fill="#7FC064"/>
              <path
                d="M137.786 12.9865C138.912 13.5726 139.128 14.0353 138.989 14.4054C138.835 14.7756 138.388 14.899 137.246 14.3283C135.178 13.2641 134.453 12.0919 134.453 12.0919C134.453 12.0919 135.703 11.9223 137.786 12.9865Z"
                fill="#7FC064"/>
              <path
                d="M140.781 20.5284C141.197 21.4538 141.074 21.8702 140.75 22.0553C140.426 22.2404 140.04 22.117 139.623 21.1916C138.852 19.5105 139.068 18.3691 139.068 18.3691C139.068 18.3691 140.009 18.8164 140.781 20.5284Z"
                fill="#7FC064"/>
              <path
                d="M142.245 11.5056C142.461 12.5081 142.23 12.8937 141.875 12.9862C141.52 13.0787 141.181 12.8474 140.965 11.8449C140.579 10.0095 141.042 8.94531 141.042 8.94531C141.042 8.94531 141.86 9.63936 142.245 11.5056Z"
                fill="#7FC064"/>
              <path
                d="M143.666 26.5584C143.62 27.5917 143.311 27.8848 142.941 27.8539C142.586 27.8385 142.308 27.4992 142.354 26.4658C142.447 24.5842 143.141 23.7051 143.141 23.7051C143.141 23.7051 143.758 24.6459 143.666 26.5584Z"
                fill="#7FC064"/>
              <path
                d="M138.572 23.6899C139.159 24.4919 139.113 24.9392 138.835 25.2014C138.557 25.4636 138.156 25.4328 137.569 24.6307C136.489 23.181 136.489 22.0088 136.489 22.0088C136.489 22.0088 137.477 22.2247 138.572 23.6899Z"
                fill="#7FC064"/>
              <path
                d="M144.823 3.50108C145.116 4.85833 144.916 5.33645 144.561 5.42899C144.206 5.52153 143.836 5.19764 143.542 3.84039C143.018 1.37267 143.388 0 143.388 0C143.388 0 144.283 0.987089 144.823 3.50108Z"
                fill="#7FC064"/>
              <path
                d="M146.86 34.3938C146.86 34.3938 142.307 15.3306 137.74 16.9038C133.172 18.477 143.31 28.9185 144.483 34.5172L146.86 34.3938Z"
                fill="#4DB05B"/>
              <path
                d="M146.181 33.5307C146.181 33.5307 139.206 18.2308 134.87 20.5134C130.518 22.7961 141.953 29.5977 143.836 34.1322L146.181 33.5307Z"
                fill="#7FC064"/>
              <path
                d="M144.838 33.8856C142.461 27.8551 136.165 22.6112 136.104 22.5495L136.212 22.3799C136.273 22.4262 142.6 27.7163 145.007 33.7931L144.838 33.8856Z"
                fill="#7FC064"/>
              <path
                d="M159.39 22.7186C157.754 25.0783 160.609 28.1476 157.878 29.6436C155.131 31.1397 155.224 30.6616 154.961 32.79C154.961 32.79 155.008 33.1139 154.714 33.3298C153.85 34.0547 150.563 34.6099 146.644 34.6099C145.548 34.6099 144.499 34.5636 143.542 34.4865C143.311 33.7925 143.079 32.6666 143.527 31.4019C143.681 30.9392 143.943 30.4611 144.314 29.9829C146.351 27.3764 147.986 27.8854 147.524 23.0579C147.061 18.215 152.878 20.3126 154.344 18.6777C155.795 17.0428 156.798 15.2075 159.406 15.9324C162.013 16.6418 161.026 20.3588 159.39 22.7186Z"
                fill="#4DB05B"/>
              <path
                d="M147.847 34.7792L147.678 34.7175C147.693 34.6404 149.931 26.2964 160.069 16.333L160.177 16.4872C150.1 26.4044 147.878 34.6867 147.847 34.7792Z"
                fill="#7FC064"/>
              <path
                d="M150.934 24.2603C148.542 24.1677 147.493 23.304 147.447 23.2577L147.555 23.1035C147.57 23.1189 148.62 23.9672 150.95 24.0598L150.934 24.2603Z"
                fill="#7FC064"/>
              <path
                d="M152.384 25.6638C151.443 25.2628 150.656 24.1369 150.131 22.4095C149.746 21.1139 149.622 19.9109 149.622 19.9109L149.792 19.8955C149.792 19.9418 150.255 24.5688 152.431 25.4941L152.384 25.6638Z"
                fill="#7FC064"/>
              <path
                d="M154.916 22.8568C154.638 22.8568 154.484 22.8413 154.468 22.8413L154.484 22.6408C154.515 22.6408 157.925 22.9956 160.579 20.3428L160.702 20.4816C158.558 22.6254 155.934 22.8568 154.916 22.8568Z"
                fill="#7FC064"/>
              <path
                d="M158.851 25.0773C157.647 24.8768 156.891 24.2753 156.459 23.7972C155.996 23.2728 155.826 22.8101 155.811 22.7793L155.965 22.7021C155.965 22.7176 156.66 24.5221 158.851 24.8768V25.0773Z"
                fill="#7FC064"/>
              <path
                d="M154.097 32.1265C151.489 32.1265 149.606 30.3374 149.591 30.3066L149.699 30.1523C149.73 30.1678 152.06 32.3887 155.1 31.8335L155.131 32.034C154.776 32.0957 154.436 32.1265 154.097 32.1265Z"
                fill="#7FC064"/>
              <path
                d="M151.52 31.4629L151.365 31.3549C151.89 30.4295 153.094 29.1648 155.917 28.5016C157.599 28.1006 159.065 28.1006 159.081 28.1006V28.3011C159.019 28.3011 153.263 28.3319 151.52 31.4629Z"
                fill="#7FC064"/>
              <path d="M160.995 43.4937L101.245 44.0798V40.5479H160.455L160.995 43.4937Z" fill="#7FC064"/>
              <path
                d="M218.198 143.76C218.198 143.76 214.279 139.719 215.621 137.421C216.979 135.123 219.248 142.357 219.248 142.357C219.248 142.357 218.06 133.797 220.513 133.643C222.967 133.488 220.513 142.357 220.513 142.357C220.513 142.357 221.424 140.29 222.303 140.167C223.183 140.043 222.936 141.925 221.346 143.76H218.198V143.76Z"
                fill="#BED5DC"/>
              <path
                d="M218.475 143.868C218.459 143.853 217.333 142.696 216.422 141.231C215.173 139.226 214.864 137.637 215.527 136.512C215.774 136.095 216.068 135.895 216.407 135.925C217.101 136.003 217.873 137.051 218.768 139.134C219.046 139.75 219.277 140.383 219.462 140.892C219.246 138.64 218.999 134.167 220.219 132.671C220.481 132.347 220.805 132.178 221.175 132.147C221.546 132.131 221.839 132.255 222.055 132.532C223.166 133.89 222.163 138.763 221.623 141.031C222.024 140.367 222.61 139.627 223.197 139.535C223.475 139.488 223.691 139.596 223.829 139.828C224.184 140.491 223.706 142.172 222.255 143.853L222.039 143.668C223.459 142.018 223.845 140.46 223.583 139.982C223.521 139.858 223.413 139.812 223.258 139.843C222.502 139.951 221.607 141.617 221.33 142.249L221.052 142.156C221.052 142.141 221.592 140.182 221.962 138.038C222.441 135.247 222.394 133.411 221.854 132.733C221.684 132.532 221.484 132.44 221.206 132.455C220.913 132.471 220.666 132.609 220.45 132.872C218.891 134.769 219.894 142.095 219.894 142.172L219.617 142.234C219.107 140.599 217.518 136.357 216.361 136.219C216.145 136.188 215.944 136.342 215.759 136.65C214.293 139.134 218.614 143.622 218.66 143.668L218.475 143.868Z"
                fill="#42428E"/>
              <path
                d="M4.93896 62.0486L16.0957 107.532L17.2222 112.143L62.8523 121.767L64.38 121.69C64.3646 121.829 64.3492 121.968 64.3492 122.107V127.243C64.3492 128.461 65.0744 129.448 65.9849 129.448H151.412C152.307 129.448 153.048 128.461 153.048 127.243C153.048 127.243 157.585 108.719 145.178 103.013C132.771 97.3062 119.84 103.475 113.204 96.3345C105.736 88.2836 108.884 62.1412 87.6349 62.0332L4.93896 62.0486Z"
                fill="#4DB05B"/>
              <path
                d="M149.176 144.239C149.608 144.223 149.917 143.36 149.809 142.573L148.034 129.263H145.642L148.204 143.313C148.296 144.239 148.898 144.254 149.176 144.239Z"
                fill="#561C17"/>
              <path
                d="M73.8101 144.239C74.2422 144.223 74.5508 143.36 74.4428 142.573L72.6682 129.263H70.2764L72.838 143.313C72.946 144.239 73.5478 144.254 73.8101 144.239Z"
                fill="#561C17"/>
              <path
                d="M17.8712 144.239C17.4391 144.223 17.1305 143.36 17.2385 142.573L19.0131 129.263H21.4049L18.8433 143.313C18.7353 144.239 18.1489 144.254 17.8712 144.239Z"
                fill="#561C17"/>
              <path
                d="M4.93896 62.1094L16.0957 107.593L24.6909 111.433L46.1712 101.639C46.1712 101.639 83.9005 101.747 108.976 101.732C105.566 91.2902 108.899 62.2019 87.6349 62.1094H4.93896Z"
                fill="#7FC064"/>
              <path
                d="M0.125631 69.698L9.43066 124.929C9.93989 127.967 11.9614 130.126 14.2761 130.095L65.4305 129.448C65.4305 129.448 69.5198 108.719 57.1131 103.012C44.7064 97.3056 30.9881 108.426 23.3342 105.11C16.6062 102.21 21.7293 70.2378 6.23639 62.4336C2.7798 60.6908 -0.723086 64.8551 0.125631 69.698Z"
                fill="#4BAE48"/>
              <path
                d="M17.0839 74.5105C17.0067 74.5105 16.9296 74.4488 16.9141 74.3408C15.5562 68.5571 11.6367 64.7784 11.5904 64.7475C11.5132 64.6704 11.4823 64.5162 11.5441 64.4082C11.6058 64.3003 11.7138 64.2694 11.791 64.3465C11.8373 64.3774 15.8494 68.2486 17.2536 74.1866C17.2845 74.31 17.2382 74.4488 17.1302 74.495C17.1147 74.5105 17.0993 74.5105 17.0839 74.5105Z"
                fill="#F4F4F4"/>
              <path
                d="M20.1392 86.155C20.0621 86.155 19.9849 86.0933 19.9695 85.9853L18.6887 80.4021C18.6578 80.2787 18.7196 80.1399 18.8121 80.0937C18.9047 80.0474 19.0127 80.1245 19.0436 80.2479L20.3244 85.8311C20.3553 85.9545 20.2935 86.0933 20.201 86.1396C20.1701 86.155 20.1547 86.155 20.1392 86.155Z"
                fill="#F4F4F4"/>
              <path
                d="M10.402 70.3461C10.3248 70.3461 10.2477 70.2999 10.2014 70.2073C10.1705 70.1456 7.09969 64.655 4.41466 65.2873C1.83765 65.8888 2.13084 69.2202 2.14627 69.359C2.1617 69.5441 2.06912 69.7138 1.93024 69.7446C1.79135 69.7755 1.66791 69.6367 1.65247 69.4516C1.65247 69.4053 1.25126 65.349 4.32208 64.6241C5.58744 64.3311 7.03797 65.1177 8.61195 66.9839C9.7693 68.3411 10.5563 69.7601 10.5872 69.8217C10.6643 69.976 10.6489 70.1765 10.5409 70.2844C10.51 70.3307 10.4483 70.3461 10.402 70.3461Z"
                fill="#F4F4F4"/>
              <path
                d="M38.9505 30.7531C38.9505 31.5551 32.9015 32.2183 25.4174 32.2183C17.9486 32.2183 11.915 33.3905 11.915 32.5731C11.915 26.7893 17.9486 21.3604 25.4174 21.3604C32.8861 21.3758 38.9505 25.5709 38.9505 30.7531Z"
                fill="#7FC064"/>
              <path
                d="M38.9197 30.8457C38.9506 31.4164 36.0341 32.095 31.7288 32.6194C31.4819 32.6502 31.2195 32.6811 30.9726 32.7119C29.306 32.897 27.4543 33.0667 25.5254 33.19C23.8743 33.298 22.2848 33.3597 20.8189 33.406C20.2942 33.4214 19.7696 33.4214 19.2758 33.4368C14.9396 33.4677 11.9614 33.1746 11.9305 32.604C11.8996 32.1104 14.0754 31.5398 17.4394 31.0616C17.7018 31.0308 17.9795 30.9845 18.2573 30.9537C19.677 30.7686 21.2818 30.5989 22.9947 30.4447C23.7508 30.383 24.5378 30.3213 25.3248 30.2751C25.8803 30.2442 26.4358 30.2134 26.9605 30.1825C29.2289 30.0591 31.343 30.0283 33.133 30.0437C33.1793 30.0437 33.2256 30.0437 33.2564 30.0437C36.6667 30.0591 38.8888 30.3522 38.9197 30.8457Z"
                fill="#4DB05B"/>
              <path
                d="M33.1323 30.0137C32.7311 30.7694 32.2373 31.448 31.6509 32.0495C31.4349 32.2809 31.2034 32.4814 30.9565 32.6819C29.4443 33.9312 27.5154 34.6869 25.4013 34.6869C23.7193 34.6869 22.1299 34.2088 20.8028 33.3605C20.201 32.9904 19.6609 32.5431 19.1671 32.0341C18.8276 31.6794 18.519 31.3092 18.2412 30.9082C19.6609 30.7231 21.2657 30.5535 22.9786 30.3992C23.7347 30.3376 24.5217 30.2759 25.3087 30.2296C25.8642 30.1987 26.4198 30.1679 26.9444 30.137C29.2437 30.0445 31.3423 29.9982 33.1323 30.0137Z"
                fill="white"/>
              <path
                d="M25.4174 22.3479C25.0934 22.3479 24.8311 22.0857 24.8311 21.7618V12.1994C24.8311 11.8755 25.0934 11.6133 25.4174 11.6133C25.7415 11.6133 26.0038 11.8755 26.0038 12.1994V21.7772C26.0038 22.0857 25.7415 22.3479 25.4174 22.3479Z"
                fill="#7FC064"/>
              <path
                d="M25.4174 23.3199C26.3463 23.3199 27.0994 22.5603 27.0994 21.6233C27.0994 20.6863 26.3463 19.9268 25.4174 19.9268C24.4884 19.9268 23.7354 20.6863 23.7354 21.6233C23.7354 22.5603 24.4884 23.3199 25.4174 23.3199Z"
                fill="#7FC064"/>
              <path
                d="M224.278 27.1758C224.047 22.3174 219.68 18.2148 214.819 18.2148C207.921 18.2148 205.668 23.4896 205.39 28.3479C205.113 33.3913 209.557 36.8461 214.418 36.8461C219.278 36.8461 224.556 33.2217 224.278 27.1758Z"
                fill="#F4F4F4"/>
              <path
                d="M222.564 27.6843C222.564 23.8131 218.706 19.6951 214.833 19.5717C209.108 19.3866 206.793 25.1858 206.793 29.057C206.793 32.9282 210.79 35.2263 214.663 35.2263C218.521 35.2417 222.564 31.5556 222.564 27.6843Z"
                fill="#B9EFD4"/>
              <path
                d="M215.652 27.5617C215.605 27.0374 215.034 26.6826 214.51 26.6826C213.985 26.6826 213.414 27.315 213.414 27.8548C213.414 28.3792 213.985 28.8264 214.51 28.8264C215.05 28.811 215.76 28.5334 215.652 27.5617Z"
                fill="#F4F4F4"/>
              <path
                d="M214.51 28.0398H214.325C214.217 28.0398 214.14 27.9473 214.14 27.8548C214.14 27.7468 213.893 27.3921 214.001 27.3921L214.109 27.1607L214.34 21.9322L214.109 22.071C214.047 22.1636 213.924 22.179 213.847 22.1173C213.754 22.0556 213.738 21.9322 213.8 21.8551L214.387 21.0068C214.433 20.9451 214.525 20.9143 214.603 20.9297C214.68 20.9606 214.742 21.0223 214.742 21.1148L215.066 27.8239C215.035 27.9473 214.618 28.0398 214.51 28.0398Z"
                fill="#F4F4F4"/>
              <path
                d="M215.174 22.1179C215.113 22.1179 215.066 22.0871 215.02 22.0408L214.295 21.5164C214.233 21.4393 214.31 21.0383 214.403 20.9766C214.48 20.9149 214.603 20.9149 214.665 21.0074L215.313 21.8094C215.375 21.8866 215.375 22.0099 215.282 22.0716C215.251 22.1025 215.221 22.1179 215.174 22.1179Z"
                fill="#F4F4F4"/>
              <path
                d="M211.054 28.6561C211.007 28.6561 210.961 28.6407 210.915 28.5944L210.344 28.0084C210.297 27.9467 210.282 27.8696 210.313 27.8079C210.344 27.7307 210.406 27.6845 210.483 27.6845L214.989 27.4531C215.097 27.4531 215.158 27.7924 215.158 27.885V28.0701C215.158 28.178 215.066 28.2551 214.973 28.2551C214.865 28.2551 214.634 28.3477 214.634 28.2397L214.788 28.0546L210.946 28.0701L211.193 28.3322C211.27 28.4094 211.27 28.5328 211.193 28.5944C211.146 28.6407 211.1 28.6561 211.054 28.6561Z"
                fill="#F4F4F4"/>
              <path
                d="M210.482 28.0701C210.436 28.0701 210.389 28.0547 210.358 28.0238C210.281 27.9621 210.266 27.8387 210.328 27.7616L210.852 27.1138C210.914 27.0367 211.037 27.0213 211.115 27.083C211.192 27.1447 211.207 27.2681 211.146 27.3452L210.945 27.8233C210.914 27.885 210.544 28.0701 210.482 28.0701Z"
                fill="#F4F4F4"/>
              <path
                d="M110.227 141.74C110.227 141.74 110.15 144.454 111.94 145.318C113.73 146.182 119.023 147.169 116.94 148.804C115.736 149.744 110.397 148.804 108.884 148.726C107.372 148.649 105.906 149.22 104.826 148.156C103.638 146.968 105.196 141.478 105.196 141.478C105.196 141.478 106.477 142.233 107.65 142.264C109.332 142.31 110.227 141.74 110.227 141.74Z"
                fill="#561C17"/>
              <path
                d="M126.77 141.724C126.77 141.724 126.693 144.439 128.483 145.302C130.273 146.166 135.566 147.153 133.483 148.788C132.279 149.729 126.94 148.788 125.427 148.711C123.915 148.634 122.449 149.204 121.369 148.14C120.181 146.953 121.739 141.462 121.739 141.462C121.739 141.462 123.02 142.218 124.193 142.248C125.89 142.295 126.77 141.724 126.77 141.724Z"
                fill="#561C17"/>
              <path
                d="M128.728 137.236C127.385 119.422 127.061 107.407 121.429 99.6958C115.257 91.2438 98.7143 85.275 92.3412 84.9048C81.9406 84.3033 77.0334 85.8457 77.0334 85.8457C69.2252 102.071 81.4622 104.23 90.5049 104.677C90.7981 104.693 91.1067 104.708 91.3999 104.724C96.878 105.063 106.615 106.467 110.303 111.896C113.22 116.199 115.241 122.245 116.105 137.853C116.275 140.845 118.775 143.174 121.784 143.081C121.784 143.066 129.222 143.637 128.728 137.236Z"
                fill="#FFC655"/>
              <path
                d="M113.976 138.146C114.146 120.286 114.701 111.171 109.347 103.259C105.905 98.1689 100.721 94.3439 93.9463 91.8453C89.1163 90.0717 83.4222 88.9766 77.0491 88.6064C66.6485 88.0049 61.9728 86.7402 61.9728 86.7402C53.5474 107.515 84.3481 103.104 92.3723 111.957C95.8598 115.813 100.196 123.155 101.045 138.747C101.214 141.74 103.714 144.068 106.723 143.976C106.708 143.991 113.914 144.685 113.976 138.146Z"
                fill="#FFC655"/>
              <path
                d="M113.914 120.61C113.914 120.502 113.374 109.906 111.599 106.205C109.346 101.516 102.834 96.627 96.0908 94.5757L96.1217 94.4678C102.896 96.5345 109.439 101.439 111.707 106.159C113.497 109.876 114.022 120.502 114.022 120.61H113.914Z"
                fill="#020202"/>
              <path
                d="M88.3755 50.4648C88.6687 50.6345 93.5141 52.8092 94.5634 60.8138C95.6127 68.8185 89.0082 61.6004 89.0082 61.6004L88.3755 50.4648Z"
                fill="#FFA076"/>
              <path
                d="M63.8094 52.9324C63.8094 52.9324 66.6025 45.082 76.5402 45.4521C86.2619 45.8069 95.5823 57.2972 97.7273 84.072C97.8198 85.167 97.2489 86.2312 96.2767 86.7556C91.4005 89.3776 76.0464 96.7345 60.8003 94.2822C58.5474 93.912 56.9734 91.8607 57.2203 89.5935C58.0844 81.3266 60.4146 61.8162 63.8094 52.9324Z"
                fill="#00A76E"/>
              <path
                d="M56.835 52.4546L63.8716 56.9581C63.8716 56.9581 81.9724 58.7627 86.7715 57.899C91.5706 57.0353 92.3268 53.5033 92.3268 53.5033C92.3268 53.5033 87.7591 47.2415 79.0559 45.6837C78.2381 45.5295 77.4202 45.5141 76.5406 45.4524C75.0592 45.3444 73.5933 45.3907 72.1427 45.5449C63.5475 46.4549 56.835 52.4546 56.835 52.4546Z"
                fill="#00A76E"/>
              <path
                d="M83.2217 45.7149V45.6069C83.2371 45.6069 84.5333 45.5298 85.0426 44.7432C85.3049 44.3422 85.3049 43.8024 85.058 43.17C84.9345 42.877 84.8265 42.5994 84.7339 42.3526C84.0395 40.6252 83.7772 39.9929 84.271 38.7744L84.379 38.8207C83.9007 39.9929 84.1476 40.5789 84.842 42.3218C84.95 42.5685 85.058 42.8462 85.166 43.1392C85.4283 43.8178 85.4283 44.3885 85.1352 44.8203C84.5951 45.6223 83.2834 45.7149 83.2217 45.7149Z"
                fill="#561C17"/>
              <path
                d="M84.3171 38.7588C84.3171 38.7588 83.5301 40.6404 84.4714 42.9231C85.3972 45.2057 83.7152 46.455 82.095 45.3445C80.4747 44.234 79.4099 46.3625 77.373 44.7276C75.3515 43.0927 84.3171 38.7588 84.3171 38.7588Z"
                fill="#561C17"/>
              <path
                d="M74.8569 44.3573L76.5698 41.7662L77.0636 41.0105L79.9955 36.584L78.7456 41.504L77.9894 44.4498C77.9894 44.4498 78.0203 45.3907 78.6838 45.6374C78.6838 45.6374 80.5202 48.2131 77.2179 48.4599C75.3198 48.5987 72.9897 47.3957 72.249 45.5449C72.9897 45.3135 74.3014 45.1902 74.8569 44.3573Z"
                fill="#FFA076"/>
              <path
                d="M76.5862 41.6739C76.5862 41.7047 76.5862 41.7356 76.5708 41.751C76.4011 43.3704 77.9905 44.4346 77.9905 44.4346H78.0059L78.762 41.4888C78.2682 41.1958 77.5584 40.8719 77.08 41.0107C76.8177 41.0724 76.6325 41.2729 76.5862 41.6739Z"
                fill="#561C17"/>
              <path
                d="M84.7648 38.2494C85.3358 35.0568 83.7001 32.1109 81.1231 31.6482C78.546 31.1855 75.9845 33.3911 75.4135 36.5837C74.8426 39.7763 76.4783 42.7221 79.0553 43.1848C81.6323 43.6321 84.1939 41.4266 84.7648 38.2494Z"
                fill="#FFA076"/>
              <path
                d="M75.876 37.5553C76.3081 38.1568 77.0488 38.3419 77.5117 37.9717C77.9747 37.6015 77.9901 36.815 77.558 36.2134C77.1259 35.6119 76.3852 35.4269 75.9223 35.797C75.4594 36.1672 75.4285 36.9538 75.876 37.5553Z"
                fill="#FFA076"/>
              <path
                d="M81.9256 41.597C81.9256 41.597 80.3207 41.3502 78.8239 40.502C78.8239 40.502 78.4073 42.0597 80.1201 42.4607C81.1232 42.7075 81.9256 41.597 81.9256 41.597Z"
                fill="#DB6B49"/>
              <path
                d="M81.9249 41.597C81.9249 41.597 81.4774 41.8438 80.5824 41.6895C79.6874 41.5199 78.8232 40.502 78.8232 40.502C78.8232 40.502 80.6596 41.3657 81.9249 41.597Z"
                fill="white"/>
              <path
                d="M80.5212 37.5403C80.552 37.5403 80.5829 37.5249 80.5983 37.494C80.6292 37.4478 80.6138 37.3706 80.5675 37.3398C80.5366 37.3244 79.8885 36.8308 79.1324 37.4015C79.0861 37.4323 79.0706 37.5095 79.1015 37.5557C79.1324 37.602 79.1941 37.602 79.2558 37.5711C79.7959 37.3244 80.4132 37.5095 80.444 37.5249C80.4749 37.5403 80.5057 37.5403 80.5212 37.5403Z"
                fill="#561C17"/>
              <path
                d="M79.4722 38.5581C79.426 38.8049 79.5185 39.0517 79.7037 39.0825C79.8889 39.1288 80.0741 38.9437 80.1358 38.697C80.1821 38.4502 80.0895 38.2034 79.9043 38.1726C79.7191 38.1417 79.5185 38.3114 79.4722 38.5581Z"
                fill="#561C17"/>
              <path
                d="M84.2245 38.7738C84.2553 38.7892 84.2862 38.7738 84.3171 38.7584C84.3634 38.7276 84.3788 38.6504 84.3479 38.6042C84.3325 38.5733 83.9158 37.8639 83.0054 38.1106C82.9437 38.126 82.9128 38.1877 82.9282 38.234C82.9437 38.2957 83.0054 38.3111 83.0517 38.3111C83.6535 38.2957 84.1473 38.6967 84.1782 38.7121C84.1782 38.7584 84.1936 38.7738 84.2245 38.7738Z"
                fill="#561C17"/>
              <path
                d="M82.8665 39.3137C82.7276 39.5296 82.7276 39.7918 82.8819 39.8843C83.0362 39.9923 83.2831 39.8997 83.422 39.6838C83.5609 39.4679 83.5609 39.2057 83.4066 39.1132C83.2523 39.0206 83.0054 39.1132 82.8665 39.3137Z"
                fill="#561C17"/>
              <path
                d="M82.913 33.0364C82.913 33.0364 82.4501 35.026 79.8885 34.9181C77.3269 34.8101 75.4134 35.5659 75.4289 37.4167C75.4443 39.2829 78.8083 43.7402 75.5832 44.6502C72.358 45.5756 73.4691 42.9844 70.1514 43.0307C66.8337 43.077 67.0188 38.6968 69.673 36.5992C72.3272 34.5017 72.6049 27.7617 80.8452 29.5354C89.0855 31.309 84.8728 37.3858 84.8728 37.3858C84.8728 37.3858 82.5118 35.242 82.913 33.0364Z"
                fill="#561C17"/>
              <path
                d="M74.6727 44.8822L74.6573 44.8205C75.3208 44.6817 75.7375 44.404 75.9535 43.9413C76.5399 42.692 75.3517 40.4094 74.3024 38.389C74.0246 37.8491 73.7623 37.3402 73.5308 36.8621C72.8981 35.5202 73.0679 34.1013 73.9938 33.1451C75.0122 32.0809 76.6479 31.8341 78.2373 32.5281C79.7805 33.1913 80.9532 33.0525 81.6476 32.8212C82.4038 32.5744 82.8204 32.158 82.8204 32.158L82.8667 32.2042C82.8513 32.2197 81.2001 33.8699 78.2065 32.5898C76.6325 31.9112 75.0431 32.1425 74.0401 33.1913C73.1296 34.1476 72.9599 35.5048 73.5926 36.8312C73.824 37.3093 74.0864 37.8183 74.3641 38.3581C75.4289 40.394 76.6325 42.7075 76.0307 43.9722C75.7838 44.4349 75.3363 44.7279 74.6727 44.8822Z"
                fill="white"/>
              <path
                d="M83.223 42.5066C82.9761 42.4603 82.7446 42.3524 82.544 42.1673C81.649 41.3653 81.6027 39.4065 82.4514 37.8025C82.9606 36.8309 83.7322 36.1522 84.4729 36.0288C84.9204 35.9517 85.337 36.0597 85.6611 36.3682C86.5561 37.1702 86.6024 39.1289 85.7537 40.7175C85.2445 41.7046 84.4729 42.3678 83.7322 42.4912C83.5625 42.522 83.3927 42.522 83.223 42.5066ZM82.6366 37.9105C81.8342 39.422 81.865 41.2727 82.6829 42.0131C82.9606 42.2598 83.3156 42.3524 83.7013 42.2907C84.3803 42.1673 85.0902 41.5349 85.5685 40.625C86.3709 39.1135 86.3555 37.2627 85.5222 36.5224C85.2445 36.2756 84.8895 36.1677 84.5038 36.2448C83.8248 36.3527 83.1304 36.9851 82.6366 37.9105Z"
                fill="#00A76E"/>
              <g opacity="0.3">
                <path
                  d="M77.2346 35.7359C76.5711 37.3091 76.8489 39.1907 77.8673 39.931C78.8858 40.6714 80.2592 39.9773 80.9227 38.4041C81.5862 36.831 81.3085 34.9493 80.29 34.209C79.2716 33.4687 77.8982 34.1628 77.2346 35.7359Z"
                  fill="white"/>
              </g>
              <g opacity="0.3">
                <path
                  d="M82.3719 37.9415C81.6929 39.5301 81.8781 41.4117 82.7731 42.152C83.6681 42.8923 84.9335 42.1983 85.6125 40.6097C86.2914 39.0211 86.1063 37.1395 85.2113 36.3991C84.3162 35.6588 83.0509 36.3529 82.3719 37.9415Z"
                  fill="white"/>
              </g>
              <path
                d="M78.6227 40.2702C78.3758 40.2239 78.1289 40.1159 77.9129 39.9617C76.7864 39.1751 76.4623 37.1547 77.1876 35.4581C77.6043 34.471 78.2987 33.7924 79.0702 33.5611C79.6103 33.4068 80.135 33.4994 80.5979 33.8233C81.7244 34.6253 82.0484 36.6457 81.3232 38.3268C80.9065 39.3139 80.2121 39.9925 79.4251 40.2239C79.1474 40.301 78.8696 40.3164 78.6227 40.2702ZM79.8264 33.7307C79.5949 33.6844 79.3634 33.6999 79.1165 33.777C78.4067 33.9775 77.7586 34.6407 77.3574 35.5507C76.6784 37.1547 76.9716 39.0517 78.0209 39.792C78.4221 40.0697 78.885 40.1468 79.3634 40.0234C80.0732 39.8229 80.7368 39.1597 81.1226 38.2497C81.8016 36.6457 81.5084 34.7486 80.459 34.0083C80.2584 33.8695 80.0578 33.777 79.8264 33.7307Z"
                fill="#00A76E"/>
              <path
                d="M82.4962 38.5892C82.4499 38.5737 82.419 38.5583 82.3881 38.5275C82.3727 38.5121 81.9406 38.0185 81.0302 38.0339C80.9222 38.0339 80.8296 37.9414 80.8296 37.8334C80.8296 37.7255 80.9222 37.6329 81.0302 37.6329C82.1258 37.6021 82.6659 38.2344 82.6968 38.2653C82.7585 38.3424 82.7585 38.4812 82.6659 38.5429C82.6042 38.5892 82.5425 38.6046 82.4962 38.5892Z"
                fill="#00A76E"/>
              <path
                d="M56.7263 52.6089C56.4795 53.3184 58.3158 54.6448 60.8311 55.5702C63.3463 56.4956 65.5839 56.6807 65.8308 55.9712C66.0777 55.2617 64.2414 53.9353 61.7261 53.0099C59.2108 52.0845 56.9732 51.8994 56.7263 52.6089Z"
                fill="#561C17"/>
              <path
                d="M73.9939 77.116L74.071 71.9801C74.0865 71.2706 73.5464 70.6845 72.8365 70.6383C69.2719 70.3761 66.7721 69.281 65.4141 67.3377C63.5161 64.654 63.7167 60.4898 64.2413 57.5902C64.5191 56.1096 64.0253 55.1225 62.2353 54.1662C62.2353 54.1662 60.6922 53.318 58.7941 52.9941C58.038 52.8707 57.2819 53.1637 57.0812 53.9195C56.7726 55.0916 56.3714 56.8961 56.1862 59.04C55.7387 64.1142 56.7109 68.5253 59.0102 71.795C61.8341 75.8205 66.3863 78.0414 72.5588 78.4116C73.3303 78.4579 73.9784 77.8718 73.9939 77.116Z"
                fill="#FFA076"/>
              <g opacity="0.5">
                <path
                  d="M60.8776 70.4537C60.7387 70.4537 60.5998 70.4383 60.4455 70.392C60.0443 70.2687 59.6585 70.0065 59.3653 69.6209C59.0721 69.2507 58.887 68.8189 58.8407 68.4024C58.8098 67.9706 58.9487 67.6004 59.2264 67.3691C59.782 66.9218 60.7541 67.1994 61.4022 67.986C62.0349 68.7726 62.0966 69.7905 61.5411 70.2378C61.3559 70.3766 61.1245 70.4537 60.8776 70.4537ZM59.89 67.5079C59.7202 67.5079 59.5659 67.5542 59.4579 67.6467C59.2727 67.8009 59.1801 68.0631 59.211 68.387C59.2419 68.7263 59.3962 69.0965 59.6585 69.4204C59.9208 69.7443 60.2449 69.9756 60.569 70.0681C60.8776 70.1607 61.1553 70.1298 61.3405 69.9756C61.7417 69.6517 61.6646 68.8343 61.1553 68.2174C60.785 67.7547 60.2912 67.5079 59.89 67.5079Z"
                  fill="#F47C53"/>
              </g>
              <path
                d="M93.0507 73.5691H75.6134C74.5178 73.5691 73.8234 72.6746 74.0857 71.5795L76.5239 60.9837C76.7708 59.8887 77.8818 58.9941 78.9774 58.9941H96.4147C97.5103 58.9941 98.2047 59.8887 97.9424 60.9837L95.5043 71.5795C95.2574 72.6746 94.1463 73.5691 93.0507 73.5691Z"
                fill="#8A4D27"/>
              <path
                d="M91.6157 73.5691H74.1784C73.0828 73.5691 72.3884 72.6746 72.6507 71.5795L75.0888 60.9837C75.3357 59.8887 76.4468 58.9941 77.5424 58.9941H94.9797C96.0753 58.9941 96.7697 59.8887 96.5074 60.9837L94.0692 71.5795C93.8223 72.6746 92.7113 73.5691 91.6157 73.5691Z"
                fill="#8A4D27"/>
              <path
                d="M87.3117 66.2737C87.1882 66.8444 86.463 67.2762 85.9074 67.2762C85.3365 67.2762 84.9816 66.8135 85.1204 66.2583C85.2439 65.6876 86.0463 65.1016 86.6173 65.1016C87.1728 65.1016 87.4351 65.7031 87.3117 66.2737Z"
                fill="white"/>
              <path
                d="M75.6291 66.0888L75.4902 66.058C75.5365 65.9037 76.5396 62.1405 77.2185 60.922C77.8975 59.6882 78.7462 59.4414 78.7771 59.4414L78.8079 59.5802C78.7925 59.5802 77.9901 59.827 77.3265 60.9992C76.663 62.1867 75.6291 66.058 75.6291 66.0888Z"
                fill="#FFC655"/>
              <path
                d="M75.0433 73.2912C74.0712 73.0599 74.3335 71.7643 74.3335 71.7489L75.2439 67.708L75.3828 67.7389L74.4724 71.7797C74.4569 71.826 74.2409 72.9673 75.0896 73.1678L75.0433 73.2912Z"
                fill="#FFC655"/>
              <path
                d="M71.5415 70.2994C71.5415 70.2994 72.7914 66.5053 74.9364 65.5799C77.0813 64.6545 80.5225 65.0863 79.3188 65.95C78.1152 66.8137 76.2635 67.4769 76.2635 67.4769C76.2635 67.4769 80.2756 66.2276 80.1521 67.2918C80.0441 68.356 76.5412 69.4048 76.5412 69.4048C76.5412 69.4048 81.4329 68.3098 81.0008 69.266C80.5688 70.2377 76.8498 71.0397 76.8498 71.0397C76.8498 71.0397 81.4329 70.1914 80.9083 71.3173C80.3836 72.4278 77.1122 71.9959 76.9887 73.0601C76.8807 74.1243 76.0628 78.304 73.0692 78.3503L71.5415 70.2994Z"
                fill="#FFA076"/>
              <path
                d="M74.8272 68.3564L74.6729 68.2793C74.9969 67.5853 76.2005 67.4002 76.2468 67.3848L76.2777 67.5544C76.2623 67.5698 75.1204 67.7549 74.8272 68.3564Z"
                fill="#F47C53"/>
              <path
                d="M74.8117 70.4694L74.6729 70.3615C75.321 69.4978 76.4783 69.3281 76.5246 69.3281L76.54 69.4978C76.54 69.4978 75.4135 69.6674 74.8117 70.4694Z"
                fill="#F47C53"/>
              <path
                d="M75.2285 72.0417L75.0742 71.9801C75.3674 71.2089 76.7717 70.993 76.8334 70.9775L76.8642 71.1472C76.8488 71.1472 75.4909 71.3631 75.2285 72.0417Z"
                fill="#F47C53"/>
              <path
                d="M98.483 67.3838C98.483 67.3838 98.7608 69.5893 96.8936 69.7898C94.5172 70.052 91.2766 67.939 92.7734 67.7385C94.1005 67.5688 96.0603 67.8156 96.0603 67.8156C96.0603 67.8156 91.0914 67.1061 91.5544 66.0574C92.0173 65.0086 96.2763 65.6718 96.2763 65.6718C96.2763 65.6718 91.4927 64.4996 92.2334 63.6668C92.9741 62.8339 96.8473 63.4046 96.8473 63.4046C96.8473 63.4046 92.1253 62.5254 93.0049 61.5692C93.8845 60.613 96.2609 61.6 97.3411 61.7851C98.375 61.9548 98.6219 62.7105 98.4213 63.7593C98.4213 63.7593 98.7916 64.9469 98.5139 65.5947C98.4058 65.8414 98.6064 66.1345 98.6064 66.335C98.6064 66.7051 98.483 67.3838 98.483 67.3838Z"
                fill="#FFA076"/>
              <path
                d="M63.162 137.251C63.162 137.251 62.946 137.745 62.4985 138.547C62.483 138.562 62.4676 138.593 62.4676 138.608C61.6652 140.012 60.1529 142.341 57.7148 144.716C56.8815 145.533 55.755 146.166 54.4434 146.628L54.4125 146.644C54.1347 146.736 53.8416 146.844 53.5484 146.921C49.2893 148.14 43.4563 147.878 38.8733 146.551L38.0863 146.304C37.7005 146.181 37.3147 146.042 36.9444 145.903C35.7562 145.441 34.7994 144.855 34.0124 144.207C33.9198 144.13 33.8118 144.037 33.7192 143.96C30.5404 141.107 30.6021 137.251 30.6021 137.251H63.162V137.251Z"
                fill="#00A76E"/>
              <path
                d="M64.2113 131.422V135.648C64.2113 138.254 56.3568 140.382 46.6506 140.382C36.9598 140.382 29.0898 138.269 29.0898 135.648V131.422C29.0898 130.095 33.6112 128.954 40.0306 128.491C42.0675 128.337 44.305 128.26 46.6351 128.26C56.3568 128.26 64.2113 129.679 64.2113 131.422Z"
                fill="#FFC655"/>
              <path
                d="M64.2113 131.421C64.2113 131.683 64.0416 131.93 63.7329 132.161C61.8966 133.549 54.9526 134.598 46.6506 134.598C44.3359 134.598 42.1138 134.521 40.0769 134.367C33.6421 133.904 29.0898 132.763 29.0898 131.436C29.0898 130.11 33.6112 128.969 40.0306 128.506C42.0675 128.352 44.305 128.275 46.6351 128.275C56.3568 128.259 64.2113 129.678 64.2113 131.421Z"
                fill="#00613E"/>
              <path
                d="M45.3081 89.1002C43.626 90.1027 42.8082 89.8559 42.4224 89.1927C42.0366 88.5295 42.2218 87.6967 43.9038 86.6941C46.9746 84.8742 49.1504 85.1981 49.1504 85.1981C49.1504 85.1981 48.4252 87.2648 45.3081 89.1002Z"
                fill="#561C17"/>
              <path
                d="M43.7332 100.528C42.1129 101.639 41.2796 101.453 40.863 100.821C40.4309 100.189 40.5698 99.3405 42.1746 98.23C45.122 96.1942 47.3286 96.3638 47.3286 96.3638C47.3286 96.3638 46.7268 98.4614 43.7332 100.528Z"
                fill="#561C17"/>
              <path
                d="M46.8506 133.689L46.4803 133.658C46.4803 133.596 46.9432 127.828 45.0297 119.376C43.2551 111.572 39.027 99.8345 28.9658 88.1745L29.2436 87.9277C39.3665 99.6494 43.61 111.464 45.3846 119.299C47.329 127.812 46.8506 133.642 46.8506 133.689Z"
                fill="#561C17"/>
              <path
                d="M37.7605 100.204L37.5444 100.066C42.4979 92.2614 39.5196 82.7761 39.4888 82.6836L39.7357 82.6064C39.7511 82.7144 42.7756 92.2923 37.7605 100.204Z"
                fill="#561C17"/>
              <path
                d="M33.5795 96.595C29.8297 96.595 26.9903 94.6979 26.9595 94.6825L27.1601 94.374C27.1909 94.4049 30.8327 96.8263 35.2769 96.086L35.3386 96.4562C34.7368 96.5487 34.1504 96.595 33.5795 96.595Z"
                fill="#561C17"/>
              <path
                d="M46.2181 125.283C38.6259 124.403 32.4688 120.671 27.9321 114.178C27.7778 113.946 27.6234 113.746 27.5771 113.7C27.5771 113.7 27.5926 113.715 27.608 113.715L27.7315 113.36C27.8395 113.391 27.9012 113.484 28.2253 113.962C32.7003 120.363 38.7648 124.049 46.2489 124.912L46.2181 125.283Z"
                fill="#561C17"/>
              <path
                d="M35.4936 121.273L35.1387 121.18C36.4503 116.291 33.2252 109.104 33.1943 109.042L33.5338 108.888C33.5493 108.903 34.3671 110.738 35.0307 113.175C35.617 115.412 36.188 118.635 35.4936 121.273Z"
                fill="#561C17"/>
              <path
                d="M28.7183 86.108C30.6781 87.8817 30.8478 88.8842 30.3231 89.4548C29.8139 90.0255 28.8109 89.9638 26.8511 88.1901C23.2865 84.9513 22.4995 82.3447 22.4995 82.3447C22.4995 82.3447 25.1074 82.8074 28.7183 86.108Z"
                fill="#561C17"/>
              <path
                d="M27.9471 93.4799C30.3389 94.5749 30.8019 95.4849 30.4778 96.1789C30.1538 96.8884 29.197 97.1198 26.7897 96.0247C22.4227 94.0197 20.895 91.7679 20.895 91.7679C20.895 91.7679 23.5183 91.444 27.9471 93.4799Z"
                fill="#561C17"/>
              <path
                d="M34.2898 107.809C35.1848 109.567 34.8916 110.369 34.2127 110.724C33.5337 111.078 32.7158 110.847 31.8208 109.089C30.1851 105.896 30.6326 103.706 30.6326 103.706C30.6326 103.706 32.6233 104.57 34.2898 107.809Z"
                fill="#561C17"/>
              <path
                d="M37.3598 90.6579C37.8073 92.5704 37.3289 93.2953 36.5882 93.465C35.8475 93.6346 35.1068 93.2182 34.6593 91.2903C33.8414 87.7892 34.7982 85.7842 34.7982 85.7842C34.7982 85.7842 36.5419 87.1106 37.3598 90.6579Z"
                fill="#561C17"/>
              <path
                d="M40.3842 119.299C40.2762 121.273 39.6281 121.813 38.8565 121.782C38.085 121.735 37.4986 121.118 37.6066 119.144C37.8072 115.566 39.2732 113.9 39.2732 113.9C39.2732 113.9 40.5694 115.659 40.3842 119.299Z"
                fill="#561C17"/>
              <path
                d="M29.5987 113.855C30.8486 115.366 30.7406 116.23 30.1542 116.723C29.5678 117.217 28.7191 117.171 27.4692 115.644C25.2008 112.883 25.1699 110.646 25.1699 110.646C25.1699 110.646 27.2994 111.047 29.5987 113.855Z"
                fill="#561C17"/>
              <path
                d="M40.2468 81.5733C40.6635 83.347 40.3857 83.9639 39.8765 84.0873C39.3672 84.2107 38.8271 83.7788 38.4105 82.0206C37.6389 78.7971 38.1945 77.0234 38.1945 77.0234C38.1945 77.0234 39.4753 78.3036 40.2468 81.5733Z"
                fill="#561C17"/>
              <path
                d="M47.1128 134.197C47.1128 134.197 37.4837 97.9216 27.8238 100.929C18.1638 103.937 39.6132 123.786 42.0822 134.444L47.1128 134.197Z"
                fill="#00A76E"/>
              <path
                d="M45.6935 132.547C45.6935 132.547 30.9567 103.443 21.7597 107.793C12.5781 112.142 36.7588 125.082 40.7246 133.688L45.6935 132.547Z"
                fill="#B8E34D"/>
              <path
                d="M42.8542 133.241C37.8236 121.782 24.5373 111.788 24.3984 111.695L24.6453 111.371C24.7842 111.479 38.1477 121.535 43.24 133.087L42.8542 133.241Z"
                fill="#F4F4F4"/>
              <path
                d="M76.1852 107.515C76.1081 107.716 76.0155 107.931 75.9229 108.132C75.3211 109.489 74.5186 110.816 73.6236 111.972C72.636 113.268 72.42 114.671 72.4972 116.09C72.4972 116.137 72.4972 116.168 72.4972 116.214C72.6206 118.327 73.3459 120.44 72.9447 122.229C72.9138 122.352 72.8829 122.476 72.8366 122.599C72.528 123.586 71.8182 124.45 70.3985 125.159C65.6766 127.488 64.9204 127.303 64.5038 129.339C64.4729 129.462 64.4575 129.586 64.4266 129.725C64.3649 130.11 64.3032 130.573 64.226 131.128C64.226 131.128 64.3186 131.745 63.7168 132.161C62.0811 133.395 56.4024 134.352 49.3349 134.552C49.2114 134.552 49.0726 134.552 48.9491 134.567C48.193 134.583 47.4369 134.598 46.6499 134.598C44.3197 134.598 42.1131 134.521 40.0762 134.367C39.5669 133.056 39.0886 130.897 40.0299 128.491C40.3694 127.612 40.9094 126.686 41.7119 125.776C45.94 120.902 49.3349 121.766 48.5479 113.145C48.5325 113.052 48.5325 112.944 48.517 112.852C48.5016 112.774 48.5016 112.682 48.4862 112.605C47.4986 103.397 59.8281 107.376 62.8989 104.276C65.9852 101.176 68.0684 97.6904 73.6236 99.0477C77.5277 100.004 77.6512 103.798 76.1852 107.515Z"
                fill="#B8E34D"/>
              <path
                d="M75.3062 100.112C70.6614 104.214 66.8191 108.178 63.6248 111.864C63.5168 111.988 63.4242 112.111 63.3162 112.219C58.6405 117.663 55.4308 122.475 53.255 126.269C53.1933 126.377 53.1316 126.485 53.0698 126.593C50.7243 130.742 49.6595 133.58 49.3355 134.552C49.212 134.552 49.0732 134.552 48.9497 134.567C49.3972 133.225 51.7736 126.655 58.5942 117.509C58.6714 117.401 58.7485 117.309 58.8257 117.201C62.6989 112.065 67.961 106.111 75.0593 99.834L75.3062 100.112Z"
                fill="#F4F4F4"/>
              <path
                d="M55.755 114.933C50.709 114.748 48.4715 113.098 48.3789 113.036L48.5949 112.743C48.6104 112.759 50.8325 114.393 55.755 114.579V114.933Z"
                fill="#F4F4F4"/>
              <path
                d="M58.811 117.602C56.805 116.847 55.1693 114.703 54.0582 111.418C53.2249 108.95 52.978 106.683 52.978 106.652L53.3484 106.605C53.3638 106.698 54.336 115.505 58.9345 117.248L58.811 117.602Z"
                fill="#F4F4F4"/>
              <path
                d="M64.1488 112.234C63.5624 112.234 63.223 112.203 63.2075 112.203L63.2384 111.833C63.3155 111.833 70.5065 112.497 76.1234 107.453L76.3703 107.731C71.849 111.802 66.3246 112.234 64.1488 112.234Z"
                fill="#F4F4F4"/>
              <path
                d="M72.4665 116.46C69.9203 116.09 68.3155 114.934 67.4204 114.008C66.4483 113.021 66.0779 112.126 66.0625 112.08L66.402 111.941C66.4174 111.972 67.8525 115.412 72.5127 116.09L72.4665 116.46Z"
                fill="#F4F4F4"/>
              <path
                d="M62.4374 129.895C56.9284 129.895 52.9472 126.486 52.9009 126.44L53.1478 126.162C53.1941 126.208 58.1475 130.419 64.5669 129.355L64.6286 129.725C63.857 129.833 63.1318 129.895 62.4374 129.895Z"
                fill="#F4F4F4"/>
              <path
                d="M56.9742 128.629L56.6655 128.428C57.7611 126.655 60.3227 124.264 66.2792 122.984C69.8284 122.228 72.9455 122.213 72.9763 122.213V122.583C72.8529 122.598 60.6622 122.676 56.9742 128.629Z"
                fill="#F4F4F4"/>
              <path
                d="M38.8575 146.582C40.5241 146.443 42.4684 146.212 44.5362 145.873C44.8139 145.826 45.0917 145.78 45.3849 145.734C51.2025 144.685 57.8842 142.634 62.4673 138.639C62.4827 138.624 62.5136 138.608 62.529 138.593L62.5136 138.577L62.2667 138.377C55.6467 144.192 44.4899 145.873 38.0859 146.351L38.8575 146.582Z"
                fill="#FFC655"/>
              <path
                d="M53.532 146.937C53.5012 146.937 53.4703 146.937 53.4395 146.937C51.0322 146.86 48.054 146.567 44.5356 145.857L44.6128 145.564C44.8751 145.611 45.122 145.672 45.3689 145.719C49.0261 146.413 52.0506 146.629 54.4271 146.66L53.532 146.937Z"
                fill="#FFC655"/>
              <path
                d="M51.1093 137.251C47.9459 139.595 42.6222 142.402 33.9807 144.222C33.8881 144.145 33.7801 144.053 33.6875 143.976C34.5054 143.806 35.2923 143.621 36.0485 143.436C36.4343 143.343 36.8046 143.251 37.175 143.143C43.5943 141.446 47.8379 139.194 50.5384 137.251H51.1093Z"
                fill="#FFC655"/>
              <path
                d="M42.1136 137.251C41.1106 139.256 39.3051 141.662 36.0646 143.436C36.0491 143.436 36.0491 143.451 36.0337 143.451L36.2343 143.698C36.5584 143.513 36.8824 143.328 37.1756 143.143C39.9841 141.369 41.5889 139.117 42.4994 137.251H42.1136Z"
                fill="#FFC655"/>
              <path
                d="M165.07 154.016C165.07 154.016 185.084 157.117 201.873 155.482C218.662 153.831 241.855 152.937 237.627 125.268C233.399 97.6137 237.967 86.0462 221.394 79.2446C204.821 72.4429 185.532 76.854 172.616 92.6628C159.684 108.502 129.393 142.187 165.07 154.016Z"
                fill="#00A76E"/>
              <path
                d="M201.147 113.207C201.039 113.207 200.931 113.13 200.916 113.006C200.9 112.883 200.993 112.775 201.116 112.76C207.289 111.958 212.103 108.456 215.035 105.665C218.23 102.642 219.973 99.7578 219.989 99.7423C220.05 99.6344 220.189 99.6035 220.297 99.6652C220.405 99.7269 220.436 99.8657 220.375 99.9737C220.359 100.005 218.584 102.92 215.344 105.989C212.35 108.827 207.459 112.389 201.163 113.207C201.163 113.207 201.163 113.207 201.147 113.207Z"
                fill="#561C17"/>
              <path
                d="M210.669 109.629C210.638 109.629 210.607 109.629 210.561 109.598C210.453 109.536 210.407 109.413 210.453 109.289C211.672 106.806 211.61 104.616 211.348 103.213C211.055 101.686 210.468 100.729 210.468 100.714C210.407 100.606 210.438 100.467 210.546 100.405C210.654 100.344 210.792 100.375 210.854 100.483C210.885 100.529 213.323 104.523 210.87 109.505C210.839 109.582 210.762 109.629 210.669 109.629Z"
                fill="#561C17"/>
              <path
                d="M150.842 148.371C150.842 148.371 150.718 151.194 148.789 151.949C146.861 152.705 141.321 153.307 143.342 155.157C144.5 156.222 150.117 155.666 151.691 155.697C153.265 155.728 154.731 156.453 155.934 155.42C157.246 154.278 156.073 148.479 156.073 148.479C156.073 148.479 154.7 149.158 153.465 149.096C151.706 149.019 150.842 148.371 150.842 148.371Z"
                fill="#561C17"/>
              <path
                d="M134.006 146.752C134.006 146.752 133.852 149.574 131.908 150.299C129.979 151.04 124.424 151.579 126.43 153.461C127.587 154.541 133.188 154.047 134.762 154.093C136.336 154.14 137.802 154.88 139.006 153.862C140.333 152.736 139.222 146.922 139.222 146.922C139.222 146.922 137.833 147.585 136.614 147.523C134.87 147.415 134.006 146.752 134.006 146.752Z"
                fill="#561C17"/>
              <path
                d="M135.286 149.08C132.03 148.71 129.638 145.841 129.854 142.572C131.119 124.079 132.848 114.779 139.097 107.067C143.109 102.117 148.973 97.4279 156.01 94.8522C162.074 92.6466 167.182 91.7058 173.802 91.8909C184.604 92.1839 189.542 94.2198 189.542 94.2198C196.162 111.694 183.338 112.82 173.941 112.481C173.632 112.466 173.324 112.466 173 112.45C167.305 112.311 157.121 112.882 152.816 118.188C149.421 122.383 146.782 128.444 144.514 144.5C144.081 147.584 141.273 149.759 138.187 149.404L135.286 149.08Z"
                fill="#8A4D27"/>
              <path
                d="M150.795 151.379C147.539 151.009 145.148 148.14 145.364 144.87C146.629 126.378 148.357 117.078 154.607 109.366C158.619 104.415 164.313 100.914 171.535 98.94C176.689 97.5365 182.661 96.9196 189.296 97.1046C200.098 97.3977 205.052 96.5186 205.052 96.5186C211.903 118.743 180.47 111.448 171.381 119.869C167.43 123.54 162.292 130.743 160.023 146.783C159.591 149.868 156.783 152.042 153.697 151.688L150.795 151.379Z"
                fill="#8A4D27"/>
              <path
                d="M172.152 65.1475C171.303 66.2425 162.971 75.7278 162.878 86.1848L170.64 84.6116L176.056 71.3013L172.152 65.1475Z"
                fill="#FFA076"/>
              <path
                d="M202.196 65.2249C202.196 65.2249 198.879 56.4336 187.521 57.0505C176.179 57.6521 168.201 62.1402 166.55 93.7887C166.55 93.7887 182.213 92.8942 200.113 103.428C203.122 105.202 207.829 104.847 208.029 101.346C208.431 94.4057 206.996 81.1262 202.196 65.2249Z"
                fill="#FFC655"/>
              <path
                d="M212.165 66.6122L202.197 69.7739C202.197 69.7739 181.55 72.1954 176.041 71.3008C170.532 70.4217 168.958 66.0724 168.958 66.0724C168.958 66.0724 174.698 59.3941 184.621 57.4662C185.547 57.2811 186.519 57.1423 187.522 57.0498C189.204 56.8955 190.824 56.7413 192.475 56.9264C208.632 58.6075 212.165 66.6122 212.165 66.6122Z"
                fill="#FFC655"/>
              <path
                d="M212.165 66.613C212.705 67.8006 210.36 68.9111 207.505 69.9753C204.635 71.0395 202.089 71.2554 201.795 70.4534C201.502 69.6514 203.601 68.1553 206.456 67.0911C209.326 66.0269 211.826 65.8264 212.165 66.613Z"
                fill="#561C17"/>
              <path
                d="M183.787 96.9498C181.843 96.6568 180.331 95.9319 179.173 94.7597C176.998 92.5542 176.982 89.5467 177.63 87.6496L180.176 86.6317C180.392 86.0147 184.543 89.0531 184.543 89.0531C184.559 89.0685 186.596 89.9939 194.558 87.3103C200.098 85.4441 203.246 82.0972 204.172 77.0847C204.882 73.306 203.647 71.3626 203.385 70.3756C203.354 70.2367 204.064 68.6019 207.027 67.6148C210.221 66.566 211.378 67.3834 211.378 67.3834C211.857 69.1725 212.829 73.2443 211.841 78.519C210.823 83.9943 207.413 91.2586 197.012 94.7751C191.318 96.7031 186.982 97.4125 183.787 96.9498Z"
                fill="#FFA076"/>
              <path
                d="M182.043 97.8301L161.211 97.2594C160.779 97.244 160.393 96.9664 160.27 96.5499L156.289 83.5019C156.088 82.8387 156.597 82.1909 157.292 82.2063L177.322 82.7461C178.232 82.777 179.034 83.3785 179.297 84.2422L183.046 96.5191C183.232 97.1977 182.722 97.8609 182.043 97.8301Z"
                fill="#7FC064"/>
              <path
                d="M180.733 97.7988L159.901 97.2282C159.469 97.2128 159.083 96.9351 158.959 96.5187L154.978 83.4706C154.778 82.8074 155.287 82.1597 155.981 82.1751L176.011 82.7149C176.921 82.7457 177.724 83.3472 177.986 84.2109L181.736 96.4879C181.921 97.1665 181.427 97.8143 180.733 97.7988Z"
                fill="#7FC064"/>
              <path
                d="M166.351 89.5166C166.366 89.0231 166.937 88.838 167.431 88.8534C167.925 88.8688 168.712 89.4241 168.696 90.1181C168.665 90.8584 168.095 90.9356 167.601 90.9201C167.092 90.9047 166.335 90.1336 166.351 89.5166Z"
                fill="#DEEBE9"/>
              <path
                d="M188.449 96.1794L159.947 95.4082L159.901 97.2282L189.004 98.0302C189.344 98.0456 189.621 97.768 189.637 97.4287V97.4132C189.637 96.75 189.112 96.1948 188.449 96.1794Z"
                fill="#7FC064"/>
              <path
                d="M179.483 89.3619C179.468 89.3156 178.557 85.4598 177.925 84.2568C177.307 83.0692 176.505 82.807 176.505 82.807L176.551 82.6836C176.582 82.699 177.415 82.9612 178.063 84.1951C178.696 85.4135 179.591 89.1768 179.637 89.331L179.483 89.3619Z"
                fill="#00A76E"/>
              <path
                d="M182.369 96.0864C181.597 96.071 181.412 95.7163 181.412 95.7009L179.915 90.7963L180.038 90.75L181.535 95.6392C181.535 95.6392 181.705 95.9168 182.384 95.9322L182.369 96.0864Z"
                fill="#00A76E"/>
              <g opacity="0.3">
                <path
                  d="M204.079 88.622C203.909 88.622 203.755 88.5757 203.632 88.4832C203.169 88.113 203.323 87.1722 203.987 86.3393C204.419 85.7995 204.974 85.4293 205.437 85.3677C205.653 85.3368 205.854 85.3831 205.993 85.5065C206.456 85.8766 206.301 86.8174 205.638 87.6503C205.206 88.1901 204.65 88.5603 204.187 88.622C204.156 88.622 204.125 88.622 204.079 88.622ZM205.545 85.6144C205.514 85.6144 205.499 85.6144 205.468 85.6144C205.082 85.6607 204.588 86 204.203 86.4781C203.663 87.1722 203.477 87.9742 203.801 88.2518C203.894 88.3289 204.002 88.3443 204.156 88.3289C204.542 88.2826 205.036 87.9433 205.422 87.4652C205.962 86.7712 206.147 85.9692 205.823 85.6915C205.746 85.6453 205.653 85.6144 205.545 85.6144Z"
                  fill="#E08B65"/>
              </g>
              <path
                d="M189.574 55.5078L187.861 52.5002L187.367 51.6211L184.436 46.4697L185.408 52.0221L185.994 55.3535L186.179 56.4023C186.303 57.1118 185.902 57.3585 185.516 58.0989C185.361 58.3919 184.328 60.2118 186.025 60.5512C188.139 60.983 191.472 58.9009 192.475 56.9113C192.475 56.9113 190.13 56.4948 189.574 55.5078Z"
                fill="#FFA076"/>
              <path
                d="M179.574 45.3594C179.574 45.3594 179.512 46.7783 180.238 48.3823C180.947 49.9864 181.765 45.4828 181.765 45.4828L179.574 45.3594Z"
                fill="#632C1E"/>
              <path
                d="M180.146 48.9066C179.869 45.5598 181.875 42.6756 184.652 42.4288C187.43 42.1975 189.899 44.7115 190.177 48.0429C190.454 51.3897 188.448 54.2739 185.671 54.5207C182.893 54.752 180.424 52.238 180.146 48.9066Z"
                fill="#FFA076"/>
              <path
                d="M189.929 46.3311L187.491 46.6088C186.951 46.6704 186.411 46.4854 186.04 46.1152C185.392 45.452 184.713 45.1898 182.506 45.7605C179.713 46.4854 177.491 45.2052 176.874 43.8017C176.241 42.3674 177.445 41.041 179.019 41.442C180.593 41.843 179.59 40.2235 180.917 39.7454C183.386 38.8354 184.281 40.7016 186.349 40.1773C189.049 39.4986 194.388 40.5011 193.123 45.8067L189.929 46.3311Z"
                fill="#561C17"/>
              <path
                d="M184.837 48.2892C184.806 48.2738 184.791 48.2584 184.775 48.2276C184.76 48.1813 184.791 48.1196 184.837 48.1042C184.868 48.0887 185.578 47.7957 186.133 48.5052C186.164 48.5514 186.164 48.6131 186.118 48.644C186.072 48.6748 186.025 48.6594 185.979 48.6286C185.532 48.2738 184.93 48.2892 184.899 48.2892C184.884 48.2892 184.868 48.2892 184.837 48.2892Z"
                fill="#561C17"/>
              <path
                d="M185.562 49.4616C185.547 49.7084 185.392 49.8935 185.223 49.8781C185.053 49.8626 184.914 49.6621 184.929 49.4154C184.945 49.1686 185.099 48.9835 185.269 48.9989C185.439 49.0144 185.562 49.2303 185.562 49.4616Z"
                fill="#561C17"/>
              <path
                d="M181.087 48.7983C181.056 48.7983 181.041 48.7829 181.025 48.7675C180.995 48.7212 180.995 48.6595 181.041 48.6287C181.056 48.6132 181.473 48.0734 182.09 48.5207C182.121 48.5516 182.136 48.6132 182.121 48.6595C182.106 48.7058 182.059 48.7212 182.013 48.7058C181.596 48.5361 181.164 48.7829 181.149 48.7983C181.118 48.7829 181.103 48.7983 181.087 48.7983Z"
                fill="#561C17"/>
              <path
                d="M182.228 49.6161C182.306 49.8474 182.244 50.0788 182.074 50.1405C181.904 50.2022 181.704 50.0634 181.627 49.832C181.549 49.6007 181.611 49.3693 181.781 49.3076C181.935 49.2459 182.136 49.3847 182.228 49.6161Z"
                fill="#561C17"/>
              <path
                d="M193.106 45.7911C193.106 45.7911 192.242 49.6932 189.958 50.4643C189.958 50.4643 191.255 47.3488 186.918 46.5777C186.934 46.5777 190.452 45.205 193.106 45.7911Z"
                fill="#632C1E"/>
              <path
                d="M190.252 48.4748C189.82 48.9066 189.172 48.9375 188.786 48.5519C188.4 48.1663 188.447 47.5185 188.879 47.0867C189.311 46.6548 189.959 46.624 190.345 47.0095C190.715 47.3797 190.684 48.0429 190.252 48.4748Z"
                fill="#FFA076"/>
              <path
                d="M186.934 46.5781C186.934 46.5781 187.907 49.4777 187.428 50.1872C186.95 50.8966 183 51.9917 180.978 51.6832C181.364 53.5957 183.077 54.9992 185.036 54.8913C185.685 54.8604 186.333 54.7216 186.95 54.4748C188.2 53.9659 189.357 53.1484 189.635 51.8066C189.712 51.4056 188.74 49.2309 188.74 49.2309C188.74 49.2309 188.262 47.9354 188.894 47.0717C188.879 47.0871 187.799 46.4702 186.934 46.5781Z"
                fill="#632C1E"/>
              <path
                d="M182.183 52.5313C182.183 52.5313 183.803 52.6084 185.455 52.084C185.455 52.084 185.547 53.688 183.788 53.7497C182.754 53.7805 182.183 52.5313 182.183 52.5313Z"
                fill="white"/>
              <path
                d="M179.22 41.1483L179.189 41.0095C179.838 40.8861 179.791 40.3617 179.776 40.3309L179.915 40.3154C179.93 40.3309 180.007 40.9941 179.22 41.1483Z"
                fill="#561C17"/>
              <path
                d="M181.534 45.1437C181.519 45.1437 180.716 45.082 179.852 44.8353C178.71 44.5114 178 43.987 177.8 43.3392C177.661 42.8919 177.676 42.568 177.846 42.3521C178.108 41.9974 178.695 42.0128 179.204 42.0128C179.281 42.0128 179.358 42.0128 179.42 42.0128V42.0745C179.343 42.0745 179.266 42.0745 179.189 42.0745C178.695 42.0745 178.124 42.0591 177.877 42.383C177.723 42.5835 177.723 42.8919 177.846 43.3083C178.093 44.0949 179.065 44.5268 179.852 44.7581C180.701 45.0049 181.519 45.0666 181.519 45.0666L181.534 45.1437Z"
                fill="white"/>
              <path
                d="M185.484 40.8708C184.975 40.8708 184.543 40.7937 184.203 40.7165C183.401 40.5006 182.969 40.1767 182.953 40.1613L182.999 40.0996C183.015 40.115 184.789 41.4414 187.613 40.4081L187.644 40.4698C186.811 40.7628 186.086 40.8708 185.484 40.8708Z"
                fill="white"/>
              <path
                d="M146.18 134.475C146.18 134.398 146.196 127.35 148.819 119.777C150.362 115.32 152.492 111.572 155.146 108.61C158.463 104.909 162.63 102.441 167.506 101.3L167.537 101.423C162.676 102.565 158.541 105.017 155.238 108.703C152.6 111.649 150.47 115.397 148.942 119.839C146.319 127.396 146.319 134.429 146.319 134.506L146.18 134.475Z"
                fill="#020202"/>
            </g>
            <defs>
              <clipPath id="clip0_225_25636">
                <rect width="238.119" height="155.975" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </div>
        <div class="svg-desktop d-none d-md-flex">
          <div class="heading-desktop-image-right me-auto">
            <svg width="394" height="433" viewBox="0 0 394 433" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_110_10535)">
                <path
                  d="M157.635 53.0891C156.993 53.0891 156.607 53.0462 156.564 53.0462L156.607 52.6181C156.693 52.6181 164.703 53.3459 171 47.7373L171.257 48.037C166.245 52.6181 160.077 53.0891 157.635 53.0891Z"
                  fill="#7FC064"/>
                <path
                  d="M166.931 57.8414C164.104 57.4132 162.305 56.1288 161.277 55.1013C160.206 53.9881 159.777 53.0034 159.777 52.9606L160.163 52.7893C160.163 52.8322 161.791 56.6426 167.017 57.4132L166.931 57.8414Z"
                  fill="#7FC064"/>
                <path
                  d="M32.6849 113.499H17.6923L16.1074 53.6456C16.1074 53.6456 18.3777 52.5325 21.9759 52.19C26.8164 51.719 31.1856 51.9331 31.1856 51.9331L32.6849 113.499Z"
                  fill="#F4F4F4"/>
                <path d="M71.1944 113.499H32.6849L31.2285 51.9331L71.1944 53.6456V113.499Z" fill="#7FC064"/>
                <path
                  d="M57.225 113.499H46.002V39.2602C46.002 39.2602 48.3151 38.618 51.3993 38.3611C54.7833 38.1042 59.1097 38.3611 59.1097 38.3611L57.225 113.499Z"
                  fill="#F4F4F4"/>
                <path d="M85.9714 113.499H57.2285L59.1133 38.3183L85.9714 39.2602V113.499Z" fill="#7FC064"/>
                <path
                  d="M84.0878 113.499H75.6491L63.9121 57.5845C63.9121 57.5845 65.3257 57.1992 67.5103 57.1136C69.9948 56.9851 72.3508 57.5845 72.3508 57.5845L84.0878 113.499Z"
                  fill="#F4F4F4"/>
                <path d="M105.719 113.499H84.0868L72.3926 57.5845L93.8105 60.7099L105.719 113.499Z" fill="#00A76E"/>
                <path
                  d="M16.363 61.5234L16.2773 61.1381C16.3202 61.1381 19.5329 60.2818 23.0026 60.4959C26.4294 60.7099 31.0986 59.554 31.1414 59.554L31.2271 59.9393C31.1842 59.9393 26.4295 61.0952 22.9598 60.8812C19.5329 60.7099 16.4059 61.5234 16.363 61.5234Z"
                  fill="#7FC064"/>
                <path
                  d="M46.0448 45.1257L46.002 44.7404C46.0876 44.7404 53.8837 43.5844 59.024 44.7404L58.9384 45.1257C53.8409 43.9698 46.0876 45.1257 46.0448 45.1257Z"
                  fill="#7FC064"/>
                <path
                  d="M58.726 49.1074C54.5281 47.2236 46.0895 47.9086 46.0038 47.9086L45.9609 47.5233C46.3036 47.4805 54.5709 46.7954 58.8974 48.7221L58.726 49.1074Z"
                  fill="#7FC064"/>
                <path
                  d="M46.0448 104.209L46.002 103.823C46.3018 103.781 53.7552 102.625 57.5248 103.823L57.3963 104.209C53.6696 103.01 46.1305 104.209 46.0448 104.209Z"
                  fill="#7FC064"/>
                <path
                  d="M46.0448 106.264L46.002 105.878C46.0876 105.878 53.2412 104.937 57.482 105.878L57.3963 106.264C53.1984 105.322 46.0876 106.221 46.0448 106.264Z"
                  fill="#7FC064"/>
                <path
                  d="M65.2771 64.0066L65.1914 63.6213C65.4056 63.5784 70.6316 62.4653 73.7586 63.6213L73.6301 64.0066C70.6316 62.8934 65.3199 64.0066 65.2771 64.0066Z"
                  fill="#00A76E"/>
                <path
                  d="M65.6228 65.7191L65.5371 65.3338C65.7513 65.291 70.5489 64.4775 74.0186 65.3338L73.933 65.7191C70.5489 64.8629 65.6656 65.7191 65.6228 65.7191Z"
                  fill="#00A76E"/>
                <path
                  d="M73.5896 103.652L73.5039 103.267C73.7181 103.224 78.687 102.411 82.0282 103.267L81.9425 103.652C78.6442 102.839 73.6324 103.652 73.5896 103.652Z"
                  fill="#00A76E"/>
                <path
                  d="M82.3722 105.836C78.1315 104.937 74.0621 105.836 74.0192 105.836L73.9336 105.45C73.9764 105.45 78.1315 104.508 82.4579 105.45L82.3722 105.836Z"
                  fill="#00A76E"/>
                <path
                  d="M161.705 99.9273C161.705 99.9273 161.448 100.655 160.848 101.811C160.848 101.854 160.806 101.897 160.806 101.897C159.778 103.952 157.764 107.334 154.552 110.802C153.481 112.001 151.981 112.9 150.268 113.585H150.225C149.84 113.713 149.454 113.885 149.069 113.97C143.5 115.726 135.832 115.383 129.792 113.456L128.764 113.114C128.25 112.943 127.736 112.729 127.265 112.515C125.723 111.83 124.438 110.973 123.41 110.031C123.281 109.903 123.153 109.774 123.024 109.646C118.869 105.493 118.912 99.8417 118.912 99.8417H161.705V99.9273Z"
                  fill="#4DB05B"/>
                <path
                  d="M163.119 91.4074V97.5726C163.119 101.383 152.795 104.466 140.073 104.466C127.351 104.466 117.027 101.383 117.027 97.5726V91.4074C117.027 89.4808 122.982 87.811 131.377 87.126C134.076 86.9119 136.989 86.7835 140.073 86.7835C152.795 86.7835 163.119 88.8814 163.119 91.4074Z"
                  fill="#7FC064"/>
                <path
                  d="M163.119 91.4074C163.119 91.7927 162.905 92.1352 162.476 92.4777C160.077 94.49 150.953 96.0313 140.073 96.0313C137.032 96.0313 134.119 95.9028 131.463 95.6888C123.024 95.0037 117.027 93.334 117.027 91.4074C117.027 89.4808 122.982 87.811 131.377 87.126C134.076 86.9119 136.989 86.7835 140.073 86.7835C152.795 86.7835 163.119 88.8814 163.119 91.4074Z"
                  fill="#00A76E"/>
                <path
                  d="M141.442 27.6149C139.214 29.0706 138.144 28.7281 137.672 27.7434C137.158 26.7587 137.415 25.5599 139.6 24.1042C143.627 21.4498 146.497 21.9207 146.497 21.9207C146.497 21.9207 145.511 24.9177 141.442 27.6149Z"
                  fill="#7FC064"/>
                <path
                  d="M141.315 46.2389C139.644 48.4224 138.53 48.4652 137.759 47.7373C136.988 47.0095 136.86 45.7679 138.53 43.5844C141.572 39.6027 144.442 39.0033 144.442 39.0033C144.442 39.0033 144.399 42.2144 141.315 46.2389Z"
                  fill="#7FC064"/>
                <path
                  d="M140.289 94.7468L139.817 94.704C139.817 94.6184 140.417 86.1841 137.933 73.8537C135.62 62.4653 130.051 45.3398 116.857 28.3427L117.243 28.0002C130.522 45.0829 136.091 62.3368 138.447 73.7681C140.931 86.1841 140.331 94.6612 140.289 94.7468Z"
                  fill="#7FC064"/>
                <path
                  d="M130.219 49.4499L129.791 49.1502C139.301 32.4957 133.561 12.2876 133.518 12.0735L133.989 11.9023C134.032 12.1163 139.815 32.5813 130.219 49.4499Z"
                  fill="#7FC064"/>
                <path
                  d="M122.897 40.6303C117.971 40.6303 114.244 37.8474 114.201 37.8474L114.458 37.3764C114.501 37.4192 119.299 40.93 125.124 39.9024L125.21 40.459C124.396 40.5446 123.625 40.6303 122.897 40.6303Z"
                  fill="#7FC064"/>
                <path
                  d="M139.473 82.4593C129.492 81.1749 121.439 75.7375 115.485 66.2757C115.271 65.9332 115.099 65.6335 115.014 65.5907C115.014 65.5907 115.014 65.5907 115.057 65.6335L115.228 65.1197C115.356 65.1625 115.442 65.291 115.87 65.976C121.739 75.3094 129.706 80.7039 139.559 81.9455L139.473 82.4593Z"
                  fill="#7FC064"/>
                <path
                  d="M125.381 76.5938L124.91 76.4654C126.623 69.3155 122.383 58.8689 122.34 58.7405L122.768 58.5264C122.768 58.5692 123.882 61.2237 124.739 64.7772C125.553 68.0739 126.324 72.7834 125.381 76.5938Z"
                  fill="#7FC064"/>
                <path
                  d="M116.514 25.303C119.084 27.9146 119.298 29.3703 118.613 30.1837C117.927 31.04 116.642 30.9116 114.072 28.3427C109.403 23.5904 108.332 19.8228 108.332 19.8228C108.332 19.8228 111.759 20.5078 116.514 25.303Z"
                  fill="#7FC064"/>
                <path
                  d="M115.483 36.0492C118.61 37.6761 119.21 38.9605 118.824 39.9881C118.396 41.0156 117.154 41.3581 113.984 39.774C108.244 36.8199 106.23 33.566 106.23 33.566C106.23 33.566 109.7 33.0951 115.483 36.0492Z"
                  fill="#7FC064"/>
                <path
                  d="M123.797 56.9851C124.953 59.554 124.611 60.7099 123.711 61.2237C122.812 61.7375 121.741 61.3949 120.584 58.8261C118.442 54.1594 119.042 50.9912 119.042 50.9912C119.042 50.9912 121.655 52.2328 123.797 56.9851Z"
                  fill="#7FC064"/>
                <path
                  d="M127.862 31.9391C128.462 34.7219 127.82 35.7923 126.834 36.0492C125.849 36.3061 124.907 35.6638 124.307 32.881C123.236 27.7861 124.521 24.832 124.521 24.832C124.521 24.832 126.791 26.7586 127.862 31.9391Z"
                  fill="#7FC064"/>
                <path
                  d="M131.805 73.7253C131.677 76.5938 130.82 77.4073 129.792 77.3217C128.807 77.2789 128.036 76.337 128.164 73.4684C128.421 68.2452 130.349 65.8048 130.349 65.8048C130.349 65.8048 132.062 68.4164 131.805 73.7253Z"
                  fill="#7FC064"/>
                <path
                  d="M117.668 65.7619C119.295 67.9883 119.167 69.2299 118.396 69.9577C117.625 70.6855 116.511 70.5999 114.883 68.3736C111.885 64.3491 111.885 61.0952 111.885 61.0952C111.885 61.0952 114.626 61.6946 117.668 65.7619Z"
                  fill="#7FC064"/>
                <path
                  d="M135.02 9.71878C135.833 13.4864 135.277 14.8136 134.291 15.0705C133.306 15.3274 132.278 14.4283 131.464 10.6607C130.008 3.81048 131.036 6.10352e-05 131.036 6.10352e-05C131.036 6.10352e-05 133.52 2.74014 135.02 9.71878Z"
                  fill="#7FC064"/>
                <path
                  d="M140.67 95.4747C140.67 95.4747 128.034 42.5569 115.354 46.9239C102.675 51.2909 130.818 80.2758 134.074 95.8172L140.67 95.4747Z"
                  fill="#4DB05B"/>
                <path
                  d="M138.788 93.0771C138.788 93.0771 119.426 50.6059 107.389 56.9423C95.3096 63.2787 127.051 82.1596 132.277 94.7468L138.788 93.0771Z"
                  fill="#7FC064"/>
                <path
                  d="M135.058 94.0618C128.461 77.3217 110.984 62.765 110.812 62.5937L111.112 62.1228C111.284 62.2512 128.846 76.9363 135.529 93.805L135.058 94.0618Z"
                  fill="#7FC064"/>
                <path
                  d="M175.456 63.0647C170.915 69.6152 178.84 78.1351 171.258 82.288C163.633 86.441 163.89 85.1138 163.162 91.0221C163.162 91.0221 163.29 91.9211 162.476 92.5205C160.078 94.5328 150.954 96.0741 140.073 96.0741C137.032 96.0741 134.119 95.9456 131.463 95.7316C130.821 93.8049 130.178 90.6795 131.42 87.1688C131.849 85.8844 132.577 84.5572 133.605 83.2299C139.259 75.9944 143.8 77.4073 142.515 64.0066C141.23 50.5631 157.379 56.3857 161.448 51.8475C165.475 47.3092 168.259 42.2144 175.498 44.2266C182.738 46.1961 179.996 56.5142 175.456 63.0647Z"
                  fill="#4DB05B"/>
                <path
                  d="M143.415 96.545L142.943 96.3738C142.986 96.1597 149.197 72.9975 177.341 45.3398L177.64 45.768C149.669 73.2972 143.5 96.2882 143.415 96.545Z"
                  fill="#7FC064"/>
                <path
                  d="M151.984 67.3461C145.344 67.0892 142.431 64.6916 142.303 64.5632L142.603 64.1351C142.645 64.1779 145.558 66.5326 152.026 66.7895L151.984 67.3461Z"
                  fill="#7FC064"/>
                <path
                  d="M156.007 71.2421C153.394 70.129 151.21 67.0036 149.753 62.2084C148.683 58.6121 148.34 55.2726 148.34 55.2726L148.811 55.2298C148.811 55.3582 150.096 68.2023 156.136 70.7712L156.007 71.2421Z"
                  fill="#7FC064"/>
                <path
                  d="M163.035 63.45C162.264 63.45 161.836 63.4072 161.793 63.4072L161.836 62.8506C161.921 62.8506 171.388 63.8354 178.756 56.4714L179.099 56.8567C173.144 62.8078 165.862 63.45 163.035 63.45Z"
                  fill="#7FC064"/>
                <path
                  d="M173.958 69.6152C170.617 69.0586 168.518 67.3889 167.319 66.0617C166.034 64.606 165.562 63.3216 165.52 63.236L165.948 63.0219C165.948 63.0647 167.876 68.0739 173.958 69.0586V69.6152Z"
                  fill="#7FC064"/>
                <path
                  d="M160.762 89.1811C153.523 89.1811 148.297 84.2147 148.254 84.1291L148.554 83.701C148.639 83.7438 155.108 89.9089 163.546 88.3677L163.632 88.9242C162.647 89.0955 161.704 89.1811 160.762 89.1811Z"
                  fill="#7FC064"/>
                <path
                  d="M153.608 87.3401L153.18 87.0404C154.636 84.4716 157.977 80.9609 165.816 79.1199C170.485 78.0067 174.555 78.0067 174.598 78.0067V78.5633C174.426 78.5633 158.448 78.6489 153.608 87.3401Z"
                  fill="#7FC064"/>
                <path d="M179.909 120.735L14.0488 122.362V112.557H178.41L179.909 120.735Z" fill="#7FC064"/>
                <path
                  d="M338.703 399.067C338.703 399.067 327.822 387.85 331.549 381.471C335.319 375.091 341.615 395.171 341.615 395.171C341.615 395.171 338.317 371.409 345.128 370.981C351.939 370.553 345.128 395.171 345.128 395.171C345.128 395.171 347.655 389.434 350.097 389.091C352.539 388.749 351.853 393.972 347.441 399.067H338.703Z"
                  fill="#BED5DC"/>
                <path
                  d="M339.47 399.367C339.427 399.324 336.3 396.113 333.773 392.045C330.303 386.48 329.446 382.07 331.288 378.944C331.973 377.788 332.787 377.232 333.73 377.318C335.657 377.532 337.799 380.443 340.284 386.223C341.055 387.935 341.697 389.691 342.211 391.104C341.612 384.853 340.926 372.437 344.31 368.284C345.038 367.385 345.938 366.914 346.966 366.828C347.994 366.785 348.808 367.128 349.408 367.899C352.492 371.666 349.708 385.195 348.208 391.489C349.322 389.648 350.95 387.593 352.578 387.336C353.349 387.208 353.948 387.507 354.334 388.149C355.319 389.99 353.991 394.657 349.965 399.324L349.365 398.81C353.306 394.229 354.377 389.905 353.648 388.578C353.477 388.235 353.177 388.107 352.749 388.192C350.65 388.492 348.165 393.116 347.394 394.871L346.623 394.614C346.623 394.571 348.123 389.134 349.151 383.183C350.479 375.434 350.35 370.339 348.851 368.455C348.38 367.899 347.823 367.642 347.052 367.684C346.238 367.727 345.552 368.113 344.953 368.84C340.626 374.107 343.411 394.443 343.411 394.657L342.64 394.828C341.226 390.29 336.814 378.516 333.601 378.131C333.002 378.045 332.445 378.474 331.931 379.33C327.861 386.223 339.855 398.682 339.984 398.81L339.47 399.367Z"
                  fill="#42428E"/>
                <path
                  d="M-253.289 172.24L-222.319 298.497L-219.192 311.299L-92.5261 338.015L-88.2854 337.8C-88.3282 338.186 -88.371 338.571 -88.371 338.956V353.213C-88.371 356.596 -86.3577 359.336 -83.8304 359.336H153.309C155.793 359.336 157.849 356.596 157.849 353.213C157.849 353.213 170.443 301.794 136.003 285.953C101.563 270.112 65.6667 287.237 47.2473 267.415C26.5147 245.066 35.2532 172.497 -23.7317 172.197L-253.289 172.24Z"
                  fill="#4DB05B"/>
                <path
                  d="M147.102 400.394C148.302 400.351 149.158 397.954 148.859 395.77L143.933 358.822H137.293L144.404 397.825C144.661 400.394 146.331 400.437 147.102 400.394Z"
                  fill="#561C17"/>
                <path
                  d="M-253.289 172.411L-222.319 298.669L-198.459 309.329L-138.832 282.143C-138.832 282.143 -34.098 282.442 35.5103 282.399C26.0435 253.415 35.2961 172.668 -23.7317 172.411H-253.289Z"
                  fill="#7FC064"/>
                <path
                  d="M355.579 75.4379C354.936 61.9515 342.814 50.5631 329.32 50.5631C310.173 50.5631 303.919 65.2054 303.148 78.6917C302.377 92.6918 314.713 102.282 328.207 102.282C341.7 102.282 356.35 92.2209 355.579 75.4379Z"
                  fill="#F4F4F4"/>
                <path
                  d="M350.821 76.8507C350.821 66.1045 340.112 54.6732 329.36 54.3307C313.468 53.8169 307.043 69.9149 307.043 80.6611C307.043 91.4074 318.137 97.7866 328.889 97.7866C339.598 97.8294 350.821 87.597 350.821 76.8507Z"
                  fill="#B9EFD4"/>
                <path
                  d="M331.631 76.5082C331.503 75.0525 329.918 74.0678 328.461 74.0678C327.005 74.0678 325.42 75.8232 325.42 77.3217C325.42 78.7773 327.005 80.0189 328.461 80.0189C329.961 79.9761 331.931 79.2055 331.631 76.5082Z"
                  fill="#F4F4F4"/>
                <path
                  d="M328.464 77.8354H327.95C327.65 77.8354 327.436 77.5786 327.436 77.3217C327.436 77.022 326.75 76.0373 327.05 76.0373L327.35 75.3951L327.993 60.8812L327.35 61.2665C327.179 61.5234 326.836 61.5662 326.622 61.395C326.365 61.2237 326.322 60.8812 326.493 60.6671L328.121 58.3124C328.25 58.1411 328.507 58.0555 328.721 58.0983C328.935 58.1839 329.106 58.3552 329.106 58.6121L330.006 77.236C329.92 77.5786 328.764 77.8354 328.464 77.8354Z"
                  fill="#F4F4F4"/>
                <path
                  d="M330.306 61.395C330.135 61.395 330.006 61.3093 329.878 61.1809L327.864 59.7252C327.693 59.5112 327.907 58.398 328.164 58.2268C328.378 58.0555 328.721 58.0555 328.892 58.3124L330.692 60.5387C330.863 60.7528 330.863 61.0953 330.606 61.2665C330.52 61.3522 330.434 61.395 330.306 61.395Z"
                  fill="#F4F4F4"/>
                <path
                  d="M318.868 79.548C318.74 79.548 318.611 79.5052 318.483 79.3767L316.898 77.7498C316.769 77.5785 316.726 77.3645 316.812 77.1932C316.898 76.9791 317.069 76.8507 317.283 76.8507L329.791 76.2085C330.091 76.2085 330.263 77.1504 330.263 77.4073V77.921C330.263 78.2207 330.006 78.4348 329.749 78.4348C329.449 78.4348 328.806 78.6917 328.806 78.392L329.235 77.8782L318.568 77.921L319.254 78.6489C319.468 78.8629 319.468 79.2055 319.254 79.3767C319.125 79.5052 318.997 79.548 318.868 79.548Z"
                  fill="#F4F4F4"/>
                <path
                  d="M317.282 77.9211C317.154 77.9211 317.025 77.8782 316.939 77.7926C316.725 77.6214 316.682 77.2788 316.854 77.0648L318.31 75.2666C318.482 75.0525 318.824 75.0097 319.038 75.181C319.253 75.3522 319.295 75.6947 319.124 75.9088L318.567 77.236C318.482 77.4073 317.453 77.9211 317.282 77.9211Z"
                  fill="#F4F4F4"/>
                <path
                  d="M38.9824 393.458C38.9824 393.458 38.7682 400.994 43.7372 403.391C48.7061 405.789 63.3988 408.529 57.616 413.067C54.2748 415.679 39.4536 413.067 35.2557 412.853C31.0577 412.639 26.9883 414.223 23.9898 411.269C20.6915 407.972 25.0179 392.73 25.0179 392.73C25.0179 392.73 28.5733 394.828 31.8288 394.914C36.4979 395.042 38.9824 393.458 38.9824 393.458Z"
                  fill="#561C17"/>
                <path
                  d="M84.9043 393.416C84.9043 393.416 84.6901 400.951 89.659 403.348C94.628 405.746 109.321 408.486 103.538 413.024C100.197 415.636 85.3754 413.024 81.1775 412.81C76.9796 412.596 72.9102 414.18 69.9117 411.226C66.6133 407.929 70.9398 392.688 70.9398 392.688C70.9398 392.688 74.4951 394.786 77.7507 394.871C82.4626 395 84.9043 393.416 84.9043 393.416Z"
                  fill="#561C17"/>
                <path
                  d="M90.3406 380.957C86.6138 331.507 85.7143 298.155 70.0792 276.748C52.9449 253.286 7.02484 236.717 -10.6664 235.69C-39.5377 234.02 -53.1595 238.301 -53.1595 238.301C-74.8345 283.341 -40.8657 289.335 -15.7638 290.577C-14.95 290.62 -14.0933 290.663 -13.2794 290.705C1.92736 291.647 28.9568 295.543 39.1945 310.614C47.2905 322.559 52.902 339.342 55.3008 382.669C55.772 390.975 62.7114 397.44 71.0644 397.183C71.0644 397.14 91.7113 398.724 90.3406 380.957Z"
                  fill="#FFC655"/>
                <path
                  d="M49.3902 383.483C49.8614 333.904 51.4035 308.601 36.5394 286.638C26.987 272.51 12.5942 261.892 -6.21075 254.956C-19.6184 250.032 -35.4248 246.993 -53.116 245.965C-81.9874 244.295 -94.9666 240.785 -94.9666 240.785C-118.355 298.455 -32.8547 286.21 -10.58 310.785C-0.899117 321.488 11.1378 341.868 13.4937 385.152C13.9649 393.458 20.9043 399.923 29.2573 399.666C29.2145 399.709 49.2188 401.636 49.3902 383.483Z"
                  fill="#FFC655"/>
                <path
                  d="M49.2176 334.803C49.2176 334.504 47.7183 305.091 42.7922 294.815C36.5382 281.8 18.4615 268.228 -0.257812 262.534L-0.172136 262.234C18.6328 267.971 36.7952 281.586 43.0921 294.687C48.061 305.005 49.5174 334.504 49.5174 334.803H49.2176Z"
                  fill="#020202"/>
                <path
                  d="M-89.8688 146.937C-89.8688 146.937 -82.1155 125.145 -54.5293 126.172C-27.5427 127.157 -1.66982 159.053 4.28436 233.378C4.54138 236.418 2.95647 239.372 0.257812 240.827C-13.2783 248.106 -55.9 268.528 -98.2218 261.72C-104.476 260.693 -108.845 254.999 -108.16 248.705C-105.761 225.757 -99.2927 171.598 -89.8688 146.937Z"
                  fill="#00A76E"/>
                <path
                  d="M-8.69685 204.222H-57.1014C-60.1427 204.222 -62.0703 201.738 -61.3421 198.699L-54.574 169.286C-53.8887 166.246 -50.8045 163.763 -47.7632 163.763H0.641365C3.68271 163.763 5.61034 166.246 4.88213 169.286L-1.88594 198.699C-2.57131 201.738 -5.65551 204.222 -8.69685 204.222Z"
                  fill="#8A4D27"/>
                <path
                  d="M-12.6812 204.222H-61.0857C-64.1271 204.222 -66.0547 201.738 -65.3265 198.699L-58.5584 169.286C-57.873 166.246 -54.7889 163.763 -51.7475 163.763H-3.34301C-0.301659 163.763 1.62596 166.246 0.897751 169.286L-5.87033 198.699C-6.55571 201.738 -9.63987 204.222 -12.6812 204.222Z"
                  fill="#8A4D27"/>
                <path
                  d="M6.38229 187.053C6.38229 187.053 7.15334 193.176 1.9702 193.732C-4.62652 194.46 -13.6221 188.595 -9.46698 188.038C-5.7831 187.567 -0.342948 188.252 -0.342948 188.252C-0.342948 188.252 -14.1361 186.283 -12.851 183.371C-11.5659 180.46 0.256764 182.301 0.256764 182.301C0.256764 182.301 -13.0224 179.047 -10.9662 176.735C-8.91011 174.423 1.84167 176.007 1.84167 176.007C1.84167 176.007 -11.2661 173.567 -8.82444 170.912C-6.3828 168.258 0.213914 170.998 3.21242 171.512C6.08242 171.983 6.7678 174.081 6.21093 176.992C6.21093 176.992 7.23901 180.289 6.46796 182.087C6.16811 182.772 6.72497 183.585 6.72497 184.142C6.72497 185.169 6.38229 187.053 6.38229 187.053Z"
                  fill="#FFA076"/>
                <path
                  d="M191.222 427.538C191.222 427.538 246.78 436.144 293.386 431.605C339.991 427.024 404.374 424.541 392.637 347.733C380.899 270.968 393.579 238.858 347.573 219.977C301.567 201.096 248.023 213.341 212.169 257.225C176.273 301.195 92.1858 394.7 191.222 427.538Z"
                  fill="#00A76E"/>
                <path
                  d="M291.37 314.253C291.07 314.253 290.77 314.039 290.727 313.696C290.684 313.354 290.942 313.054 291.284 313.011C308.419 310.785 321.783 301.066 329.922 293.317C338.789 284.925 343.63 276.919 343.672 276.877C343.844 276.577 344.229 276.491 344.529 276.662C344.829 276.834 344.915 277.219 344.743 277.519C344.7 277.604 339.774 285.696 330.779 294.216C322.469 302.094 308.89 311.984 291.413 314.253C291.413 314.253 291.413 314.253 291.37 314.253Z"
                  fill="#561C17"/>
                <path
                  d="M317.802 304.32C317.716 304.32 317.63 304.32 317.502 304.234C317.202 304.063 317.073 303.721 317.202 303.378C320.586 296.485 320.415 290.406 319.686 286.51C318.873 282.271 317.245 279.617 317.245 279.574C317.073 279.274 317.159 278.889 317.459 278.717C317.759 278.546 318.144 278.632 318.316 278.932C318.401 279.06 325.169 290.149 318.359 303.978C318.273 304.192 318.059 304.32 317.802 304.32Z"
                  fill="#561C17"/>
                <path
                  d="M151.727 411.868C151.727 411.868 151.384 419.703 146.029 421.801C140.675 423.899 125.297 425.569 130.908 430.706C134.121 433.66 149.713 432.119 154.083 432.205C158.452 432.29 162.521 434.303 165.862 431.434C169.503 428.266 166.248 412.168 166.248 412.168C166.248 412.168 162.436 414.052 159.009 413.88C154.125 413.666 151.727 411.868 151.727 411.868Z"
                  fill="#561C17"/>
                <path
                  d="M104.992 407.373C104.992 407.373 104.564 415.208 99.1663 417.22C93.8118 419.275 78.3909 420.774 83.9596 425.997C87.1723 428.994 102.722 427.624 107.091 427.752C111.46 427.881 115.53 429.936 118.871 427.11C122.555 423.985 119.471 407.844 119.471 407.844C119.471 407.844 115.615 409.685 112.231 409.514C107.391 409.214 104.992 407.373 104.992 407.373Z"
                  fill="#561C17"/>
                <path
                  d="M108.544 413.838C99.5052 412.81 92.8656 404.847 93.4653 395.77C96.9779 344.437 101.776 318.62 119.124 297.213C130.261 283.47 146.539 270.454 166.072 263.305C182.907 257.182 197.085 254.571 215.462 255.084C245.447 255.898 259.154 261.549 259.154 261.549C277.531 310.057 241.934 313.183 215.847 312.241C214.991 312.198 214.134 312.198 213.234 312.155C197.428 311.77 169.156 313.354 157.205 328.082C147.781 339.727 140.456 356.553 134.159 401.122C132.96 409.685 125.164 415.721 116.597 414.737L108.544 413.838Z"
                  fill="#8A4D27"/>
                <path
                  d="M151.598 420.217C142.56 419.189 135.92 411.226 136.52 402.15C140.033 350.816 144.83 324.999 162.179 303.592C173.316 289.849 189.122 280.13 209.17 274.65C223.477 270.754 240.054 269.042 258.474 269.555C288.459 270.369 302.209 267.928 302.209 267.928C321.228 329.623 233.972 309.372 208.741 332.748C197.775 342.938 183.511 362.932 177.214 407.458C176.015 416.021 168.219 422.058 159.651 421.073L151.598 420.217Z"
                  fill="#8A4D27"/>
                <path
                  d="M210.883 180.845C208.527 183.885 185.396 210.215 185.139 239.243L206.685 234.876L221.72 197.928L210.883 180.845Z"
                  fill="#FFA076"/>
                <path
                  d="M294.283 181.059C294.283 181.059 285.073 156.656 253.546 158.368C222.062 160.038 199.915 172.497 195.332 260.35C195.332 260.35 238.81 257.867 288.5 287.109C296.853 292.033 309.918 291.048 310.475 281.329C311.589 262.063 307.605 225.2 294.283 181.059Z"
                  fill="#FFC655"/>
                <path
                  d="M321.956 184.913L294.284 193.689C294.284 193.689 236.97 200.411 221.677 197.928C206.385 195.488 202.016 183.414 202.016 183.414C202.016 183.414 217.951 164.876 245.494 159.524C248.064 159.01 250.763 158.625 253.547 158.368C258.216 157.94 262.714 157.512 267.297 158.026C312.147 162.692 321.956 184.913 321.956 184.913Z"
                  fill="#FFC655"/>
                <path
                  d="M321.955 184.913C323.455 188.209 316.944 191.292 309.019 194.246C301.051 197.2 293.984 197.799 293.17 195.573C292.356 193.347 298.181 189.194 306.106 186.24C314.074 183.286 321.013 182.729 321.955 184.913Z"
                  fill="#561C17"/>
                <path
                  d="M243.18 269.127C237.783 268.314 233.585 266.302 230.372 263.048C224.332 256.925 224.29 248.577 226.089 243.311L233.157 240.485C233.756 238.772 245.279 247.207 245.279 247.207C245.322 247.249 250.976 249.818 273.08 242.369C288.458 237.188 297.196 227.898 299.766 213.983C301.737 203.494 298.31 198.099 297.582 195.359C297.496 194.974 299.466 190.436 307.691 187.695C316.558 184.784 319.771 187.053 319.771 187.053C321.099 192.02 323.797 203.323 321.056 217.965C318.229 233.164 308.762 253.329 279.891 263.09C264.084 268.442 252.047 270.412 243.18 269.127Z"
                  fill="#FFA076"/>
                <path
                  d="M238.34 271.568L180.512 269.983C179.312 269.941 178.241 269.17 177.899 268.014L166.847 231.794C166.29 229.953 167.704 228.154 169.631 228.197L225.232 229.696C227.76 229.781 229.987 231.451 230.715 233.849L241.124 267.928C241.638 269.812 240.225 271.653 238.34 271.568Z"
                  fill="#7FC064"/>
                <path
                  d="M234.701 271.482L176.873 269.898C175.674 269.855 174.603 269.084 174.26 267.928L163.208 231.708C162.652 229.867 164.065 228.069 165.993 228.112L221.594 229.61C224.121 229.696 226.349 231.365 227.077 233.763L237.486 267.843C238 269.727 236.629 271.525 234.701 271.482Z"
                  fill="#7FC064"/>
                <path
                  d="M194.778 248.491C194.821 247.121 196.406 246.607 197.776 246.65C199.147 246.693 201.332 248.234 201.289 250.161C201.203 252.216 199.618 252.43 198.248 252.387C196.834 252.344 194.735 250.204 194.778 248.491Z"
                  fill="#DEEBE9"/>
                <path
                  d="M256.119 266.987L177.002 264.846L176.873 269.898L257.661 272.124C258.604 272.167 259.375 271.396 259.418 270.454V270.412C259.418 268.571 257.961 267.029 256.119 266.987Z"
                  fill="#7FC064"/>
                <path
                  d="M231.232 248.063C231.189 247.934 228.662 237.231 226.906 233.891C225.192 230.595 222.965 229.867 222.965 229.867L223.093 229.524C223.179 229.567 225.492 230.295 227.291 233.72C229.048 237.103 231.532 247.549 231.66 247.977L231.232 248.063Z"
                  fill="#00A76E"/>
                <path
                  d="M239.243 266.73C237.101 266.687 236.587 265.702 236.587 265.659L232.432 252.045L232.774 251.916L236.929 265.488C236.929 265.488 237.401 266.259 239.285 266.301L239.243 266.73Z"
                  fill="#00A76E"/>
                <g opacity="0.3">
                  <path
                    d="M299.509 246.008C299.038 246.008 298.61 245.879 298.267 245.622C296.982 244.595 297.41 241.983 299.252 239.671C300.451 238.173 301.994 237.145 303.279 236.974C303.878 236.888 304.435 237.017 304.821 237.359C306.106 238.387 305.677 240.999 303.835 243.311C302.636 244.809 301.094 245.837 299.809 246.008C299.723 246.008 299.638 246.008 299.509 246.008ZM303.578 237.659C303.493 237.659 303.45 237.659 303.364 237.659C302.293 237.788 300.923 238.729 299.852 240.057C298.353 241.983 297.838 244.21 298.738 244.98C298.995 245.194 299.295 245.237 299.723 245.194C300.794 245.066 302.165 244.124 303.236 242.797C304.735 240.87 305.249 238.644 304.35 237.873C304.135 237.745 303.878 237.659 303.578 237.659Z"
                    fill="#E08B65"/>
                </g>
                <path
                  d="M259.245 154.087L254.49 145.738L253.119 143.298L244.98 128.998L247.679 144.411L249.307 153.659L249.821 156.57C250.164 158.539 249.05 159.224 247.979 161.279C247.551 162.093 244.681 167.145 249.393 168.087C255.261 169.286 264.514 163.506 267.298 157.983C267.298 157.983 260.787 156.827 259.245 154.087Z"
                  fill="#FFA076"/>
                <path
                  d="M231.486 125.915C231.486 125.915 231.315 129.854 233.328 134.307C235.298 138.759 237.569 126.258 237.569 126.258L231.486 125.915Z"
                  fill="#632C1E"/>
                <path
                  d="M233.075 135.762C232.304 126.472 237.873 118.466 245.583 117.781C253.294 117.138 260.147 124.117 260.919 133.365C261.69 142.655 256.121 150.662 248.41 151.347C240.7 151.989 233.846 145.01 233.075 135.762Z"
                  fill="#FFA076"/>
                <path
                  d="M260.23 128.613L253.462 129.383C251.963 129.554 250.463 129.041 249.435 128.013C247.636 126.172 245.751 125.444 239.626 127.028C231.873 129.041 225.704 125.487 223.991 121.591C222.235 117.609 225.576 113.927 229.945 115.041C234.314 116.154 231.53 111.658 235.214 110.331C242.068 107.805 244.552 112.985 250.292 111.53C257.788 109.646 272.61 112.429 269.097 127.157L260.23 128.613Z"
                  fill="#561C17"/>
                <path
                  d="M246.097 134.05C246.011 134.007 245.968 133.964 245.925 133.879C245.882 133.75 245.968 133.579 246.097 133.536C246.182 133.493 248.153 132.68 249.695 134.649C249.78 134.778 249.78 134.949 249.652 135.035C249.523 135.12 249.395 135.077 249.266 134.992C248.024 134.007 246.354 134.05 246.268 134.05C246.225 134.05 246.182 134.05 246.097 134.05Z"
                  fill="#561C17"/>
                <path
                  d="M248.109 137.304C248.066 137.989 247.638 138.502 247.167 138.46C246.696 138.417 246.31 137.86 246.353 137.175C246.396 136.49 246.824 135.976 247.295 136.019C247.766 136.062 248.109 136.661 248.109 137.304Z"
                  fill="#561C17"/>
                <path
                  d="M235.686 135.463C235.6 135.463 235.558 135.42 235.515 135.377C235.429 135.249 235.429 135.077 235.558 134.992C235.6 134.949 236.757 133.45 238.47 134.692C238.556 134.778 238.599 134.949 238.556 135.077C238.513 135.206 238.385 135.249 238.256 135.206C237.1 134.735 235.9 135.42 235.857 135.463C235.772 135.42 235.729 135.463 235.686 135.463Z"
                  fill="#561C17"/>
                <path
                  d="M238.854 137.732C239.068 138.374 238.896 139.016 238.425 139.187C237.954 139.359 237.397 138.973 237.183 138.331C236.969 137.689 237.14 137.047 237.611 136.876C238.04 136.704 238.597 137.09 238.854 137.732Z"
                  fill="#561C17"/>
                <path
                  d="M269.05 127.114C269.05 127.114 266.651 137.946 260.312 140.087C260.312 140.087 263.91 131.438 251.873 129.298C251.916 129.298 261.682 125.487 269.05 127.114Z"
                  fill="#632C1E"/>
                <path
                  d="M261.127 134.564C259.927 135.762 258.128 135.848 257.057 134.778C255.986 133.707 256.115 131.909 257.314 130.71C258.514 129.512 260.313 129.426 261.384 130.496C262.412 131.524 262.326 133.365 261.127 134.564Z"
                  fill="#FFA076"/>
                <path
                  d="M251.917 129.298C251.917 129.298 254.616 137.347 253.288 139.316C251.96 141.285 240.994 144.325 235.383 143.469C236.454 148.778 241.208 152.674 246.649 152.374C248.448 152.289 250.247 151.903 251.96 151.218C255.43 149.805 258.643 147.536 259.414 143.811C259.628 142.698 256.929 136.662 256.929 136.662C256.929 136.662 255.601 133.065 257.358 130.668C257.315 130.71 254.316 128.998 251.917 129.298Z"
                  fill="#632C1E"/>
                <path
                  d="M238.729 145.824C238.729 145.824 243.226 146.038 247.81 144.582C247.81 144.582 248.067 149.035 243.183 149.206C240.313 149.292 238.729 145.824 238.729 145.824Z"
                  fill="white"/>
                <path
                  d="M230.504 114.227L230.418 113.842C232.217 113.499 232.089 112.044 232.046 111.958L232.431 111.915C232.474 111.958 232.688 113.799 230.504 114.227Z"
                  fill="#561C17"/>
                <path
                  d="M236.927 125.316C236.884 125.316 234.657 125.145 232.258 124.46C229.088 123.56 227.118 122.105 226.561 120.307C226.175 119.065 226.218 118.166 226.69 117.567C227.418 116.582 229.046 116.625 230.459 116.625C230.673 116.625 230.887 116.625 231.059 116.625V116.796C230.845 116.796 230.63 116.796 230.416 116.796C229.045 116.796 227.461 116.753 226.775 117.652C226.347 118.209 226.347 119.065 226.69 120.221C227.375 122.405 230.074 123.603 232.258 124.246C234.614 124.931 236.884 125.102 236.884 125.102L236.927 125.316Z"
                  fill="white"/>
                <path
                  d="M247.89 113.456C246.477 113.456 245.277 113.242 244.335 113.028C242.107 112.429 240.908 111.53 240.865 111.487L240.994 111.316C241.037 111.359 245.963 115.041 253.802 112.172L253.887 112.343C251.574 113.157 249.561 113.456 247.89 113.456Z"
                  fill="white"/>
                <path
                  d="M138.787 373.293C138.787 373.079 138.83 353.513 146.112 332.492C150.396 320.118 156.307 309.715 163.675 301.494C172.884 291.219 184.45 284.369 197.986 281.201L198.072 281.543C184.579 284.711 173.099 291.519 163.932 301.751C156.607 309.929 150.695 320.332 146.455 332.663C139.173 353.642 139.173 373.165 139.173 373.379L138.787 373.293Z"
                  fill="#020202"/>
              </g>
              <defs>
                <clipPath id="clip0_110_10535">
                  <rect width="661" height="432.975" fill="white" transform="translate(-267)"/>
                </clipPath>
              </defs>
            </svg>
          </div>
          <div class="heading-desktop-image-right">
            <svg width="444" height="433" viewBox="0 0 444 433" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M424.635 53.0891C423.993 53.0891 423.607 53.0462 423.564 53.0462L423.607 52.6181C423.693 52.6181 431.703 53.3459 438 47.7373L438.257 48.037C433.245 52.6181 427.077 53.0891 424.635 53.0891Z"
                fill="#7FC064"/>
              <path
                d="M433.931 57.8414C431.104 57.4132 429.305 56.1288 428.277 55.1013C427.206 53.9881 426.777 53.0034 426.777 52.9606L427.163 52.7893C427.163 52.8322 428.791 56.6426 434.017 57.4132L433.931 57.8414Z"
                fill="#7FC064"/>
              <path
                d="M299.685 113.499H284.692L283.107 53.6456C283.107 53.6456 285.378 52.5325 288.976 52.19C293.816 51.719 298.186 51.9331 298.186 51.9331L299.685 113.499Z"
                fill="#F4F4F4"/>
              <path d="M338.194 113.499H299.685L298.229 51.9331L338.194 53.6456V113.499Z" fill="#7FC064"/>
              <path
                d="M324.225 113.499H313.002V39.2602C313.002 39.2602 315.315 38.618 318.399 38.3611C321.783 38.1042 326.11 38.3611 326.11 38.3611L324.225 113.499Z"
                fill="#F4F4F4"/>
              <path d="M352.971 113.499H324.229L326.113 38.3183L352.971 39.2602V113.499Z" fill="#7FC064"/>
              <path
                d="M351.088 113.499H342.649L330.912 57.5845C330.912 57.5845 332.326 57.1992 334.51 57.1136C336.995 56.9851 339.351 57.5845 339.351 57.5845L351.088 113.499Z"
                fill="#F4F4F4"/>
              <path d="M372.719 113.499H351.087L339.393 57.5845L360.811 60.7099L372.719 113.499Z" fill="#00A76E"/>
              <path
                d="M283.363 61.5234L283.277 61.1381C283.32 61.1381 286.533 60.2818 290.003 60.4959C293.429 60.7099 298.099 59.554 298.141 59.554L298.227 59.9393C298.184 59.9393 293.429 61.0952 289.96 60.8812C286.533 60.7099 283.406 61.5234 283.363 61.5234Z"
                fill="#7FC064"/>
              <path
                d="M313.045 45.1257L313.002 44.7404C313.088 44.7404 320.884 43.5844 326.024 44.7404L325.938 45.1257C320.841 43.9698 313.088 45.1257 313.045 45.1257Z"
                fill="#7FC064"/>
              <path
                d="M325.726 49.1074C321.528 47.2236 313.089 47.9086 313.004 47.9086L312.961 47.5233C313.304 47.4805 321.571 46.7954 325.897 48.7221L325.726 49.1074Z"
                fill="#7FC064"/>
              <path
                d="M313.045 104.209L313.002 103.823C313.302 103.781 320.755 102.625 324.525 103.823L324.396 104.209C320.67 103.01 313.13 104.209 313.045 104.209Z"
                fill="#7FC064"/>
              <path
                d="M313.045 106.264L313.002 105.878C313.088 105.878 320.241 104.937 324.482 105.878L324.396 106.264C320.198 105.322 313.088 106.221 313.045 106.264Z"
                fill="#7FC064"/>
              <path
                d="M332.277 64.0066L332.191 63.6213C332.406 63.5784 337.632 62.4653 340.759 63.6213L340.63 64.0066C337.632 62.8934 332.32 64.0066 332.277 64.0066Z"
                fill="#00A76E"/>
              <path
                d="M332.623 65.7191L332.537 65.3338C332.751 65.291 337.549 64.4775 341.019 65.3338L340.933 65.7191C337.549 64.8629 332.666 65.7191 332.623 65.7191Z"
                fill="#00A76E"/>
              <path
                d="M340.59 103.652L340.504 103.267C340.718 103.224 345.687 102.411 349.028 103.267L348.943 103.652C345.644 102.839 340.632 103.652 340.59 103.652Z"
                fill="#00A76E"/>
              <path
                d="M349.372 105.836C345.131 104.937 341.062 105.836 341.019 105.836L340.934 105.45C340.976 105.45 345.131 104.508 349.458 105.45L349.372 105.836Z"
                fill="#00A76E"/>
              <path
                d="M428.705 99.9273C428.705 99.9273 428.448 100.655 427.848 101.811C427.848 101.854 427.806 101.897 427.806 101.897C426.778 103.952 424.764 107.334 421.552 110.802C420.481 112.001 418.981 112.9 417.268 113.585H417.225C416.84 113.713 416.454 113.885 416.069 113.97C410.5 115.726 402.832 115.383 396.792 113.456L395.764 113.114C395.25 112.943 394.736 112.729 394.265 112.515C392.723 111.83 391.438 110.973 390.41 110.031C390.281 109.903 390.153 109.774 390.024 109.646C385.869 105.493 385.912 99.8417 385.912 99.8417H428.705V99.9273Z"
                fill="#4DB05B"/>
              <path
                d="M430.119 91.4074V97.5726C430.119 101.383 419.795 104.466 407.073 104.466C394.351 104.466 384.027 101.383 384.027 97.5726V91.4074C384.027 89.4808 389.982 87.811 398.377 87.126C401.076 86.9119 403.989 86.7835 407.073 86.7835C419.795 86.7835 430.119 88.8814 430.119 91.4074Z"
                fill="#7FC064"/>
              <path
                d="M430.119 91.4074C430.119 91.7927 429.905 92.1352 429.476 92.4777C427.077 94.49 417.953 96.0313 407.073 96.0313C404.032 96.0313 401.119 95.9028 398.463 95.6888C390.024 95.0037 384.027 93.334 384.027 91.4074C384.027 89.4808 389.982 87.811 398.377 87.126C401.076 86.9119 403.989 86.7835 407.073 86.7835C419.795 86.7835 430.119 88.8814 430.119 91.4074Z"
                fill="#00A76E"/>
              <path
                d="M408.442 27.6149C406.214 29.0706 405.144 28.7281 404.672 27.7434C404.158 26.7587 404.415 25.5599 406.6 24.1042C410.627 21.4498 413.497 21.9207 413.497 21.9207C413.497 21.9207 412.511 24.9177 408.442 27.6149Z"
                fill="#7FC064"/>
              <path
                d="M408.315 46.2389C406.644 48.4224 405.53 48.4652 404.759 47.7373C403.988 47.0095 403.86 45.7679 405.53 43.5844C408.572 39.6027 411.442 39.0033 411.442 39.0033C411.442 39.0033 411.399 42.2144 408.315 46.2389Z"
                fill="#7FC064"/>
              <path
                d="M407.289 94.7468L406.817 94.704C406.817 94.6184 407.417 86.1841 404.933 73.8537C402.62 62.4653 397.051 45.3398 383.857 28.3427L384.243 28.0002C397.522 45.0829 403.091 62.3368 405.447 73.7681C407.931 86.1841 407.331 94.6612 407.289 94.7468Z"
                fill="#7FC064"/>
              <path
                d="M397.219 49.4499L396.791 49.1502C406.301 32.4957 400.561 12.2876 400.518 12.0735L400.989 11.9023C401.032 12.1163 406.815 32.5813 397.219 49.4499Z"
                fill="#7FC064"/>
              <path
                d="M389.897 40.6303C384.971 40.6303 381.244 37.8474 381.201 37.8474L381.458 37.3764C381.501 37.4192 386.299 40.93 392.124 39.9024L392.21 40.459C391.396 40.5446 390.625 40.6303 389.897 40.6303Z"
                fill="#7FC064"/>
              <path
                d="M406.473 82.4593C396.492 81.1749 388.439 75.7375 382.485 66.2757C382.271 65.9332 382.099 65.6335 382.014 65.5907C382.014 65.5907 382.014 65.5907 382.057 65.6335L382.228 65.1197C382.356 65.1625 382.442 65.291 382.87 65.976C388.739 75.3094 396.706 80.7039 406.559 81.9455L406.473 82.4593Z"
                fill="#7FC064"/>
              <path
                d="M392.381 76.5938L391.91 76.4654C393.623 69.3155 389.383 58.8689 389.34 58.7405L389.768 58.5264C389.768 58.5692 390.882 61.2237 391.739 64.7772C392.553 68.0739 393.324 72.7834 392.381 76.5938Z"
                fill="#7FC064"/>
              <path
                d="M383.514 25.303C386.084 27.9146 386.298 29.3703 385.613 30.1837C384.927 31.04 383.642 30.9116 381.072 28.3427C376.403 23.5904 375.332 19.8228 375.332 19.8228C375.332 19.8228 378.759 20.5078 383.514 25.303Z"
                fill="#7FC064"/>
              <path
                d="M382.483 36.0492C385.61 37.6761 386.21 38.9605 385.824 39.9881C385.396 41.0156 384.154 41.3581 380.984 39.774C375.244 36.8199 373.23 33.566 373.23 33.566C373.23 33.566 376.7 33.0951 382.483 36.0492Z"
                fill="#7FC064"/>
              <path
                d="M390.797 56.9851C391.953 59.554 391.611 60.7099 390.711 61.2237C389.812 61.7375 388.741 61.3949 387.584 58.8261C385.442 54.1594 386.042 50.9912 386.042 50.9912C386.042 50.9912 388.655 52.2328 390.797 56.9851Z"
                fill="#7FC064"/>
              <path
                d="M394.862 31.9391C395.462 34.7219 394.82 35.7923 393.834 36.0492C392.849 36.3061 391.907 35.6638 391.307 32.881C390.236 27.7861 391.521 24.832 391.521 24.832C391.521 24.832 393.791 26.7586 394.862 31.9391Z"
                fill="#7FC064"/>
              <path
                d="M398.805 73.7253C398.677 76.5938 397.82 77.4073 396.792 77.3217C395.807 77.2789 395.036 76.337 395.164 73.4684C395.421 68.2452 397.349 65.8048 397.349 65.8048C397.349 65.8048 399.062 68.4164 398.805 73.7253Z"
                fill="#7FC064"/>
              <path
                d="M384.668 65.7619C386.295 67.9883 386.167 69.2299 385.396 69.9577C384.625 70.6855 383.511 70.5999 381.883 68.3736C378.885 64.3491 378.885 61.0952 378.885 61.0952C378.885 61.0952 381.626 61.6946 384.668 65.7619Z"
                fill="#7FC064"/>
              <path
                d="M402.02 9.71878C402.833 13.4864 402.277 14.8136 401.291 15.0705C400.306 15.3274 399.278 14.4283 398.464 10.6607C397.008 3.81048 398.036 6.10352e-05 398.036 6.10352e-05C398.036 6.10352e-05 400.52 2.74014 402.02 9.71878Z"
                fill="#7FC064"/>
              <path
                d="M407.67 95.4747C407.67 95.4747 395.034 42.5569 382.354 46.9239C369.675 51.2909 397.818 80.2758 401.074 95.8172L407.67 95.4747Z"
                fill="#4DB05B"/>
              <path
                d="M405.788 93.0771C405.788 93.0771 386.426 50.6059 374.389 56.9423C362.31 63.2787 394.051 82.1596 399.277 94.7468L405.788 93.0771Z"
                fill="#7FC064"/>
              <path
                d="M402.058 94.0618C395.461 77.3217 377.984 62.765 377.812 62.5937L378.112 62.1228C378.284 62.2512 395.846 76.9363 402.529 93.805L402.058 94.0618Z"
                fill="#7FC064"/>
              <path
                d="M442.456 63.0647C437.915 69.6152 445.84 78.1351 438.258 82.288C430.633 86.441 430.89 85.1138 430.162 91.0221C430.162 91.0221 430.29 91.9211 429.476 92.5205C427.078 94.5328 417.954 96.0741 407.073 96.0741C404.032 96.0741 401.119 95.9456 398.463 95.7316C397.821 93.8049 397.178 90.6795 398.42 87.1688C398.849 85.8844 399.577 84.5572 400.605 83.2299C406.259 75.9944 410.8 77.4073 409.515 64.0066C408.23 50.5631 424.379 56.3857 428.448 51.8475C432.475 47.3092 435.259 42.2144 442.498 44.2266C449.738 46.1961 446.996 56.5142 442.456 63.0647Z"
                fill="#4DB05B"/>
              <path
                d="M410.415 96.545L409.943 96.3738C409.986 96.1597 416.197 72.9975 444.341 45.3398L444.64 45.768C416.669 73.2972 410.5 96.2882 410.415 96.545Z"
                fill="#7FC064"/>
              <path
                d="M418.984 67.3461C412.344 67.0892 409.431 64.6916 409.303 64.5632L409.603 64.1351C409.645 64.1779 412.558 66.5326 419.026 66.7895L418.984 67.3461Z"
                fill="#7FC064"/>
              <path
                d="M423.007 71.2421C420.394 70.129 418.21 67.0036 416.753 62.2084C415.683 58.6121 415.34 55.2726 415.34 55.2726L415.811 55.2298C415.811 55.3582 417.096 68.2023 423.136 70.7712L423.007 71.2421Z"
                fill="#7FC064"/>
              <path
                d="M430.035 63.45C429.264 63.45 428.836 63.4072 428.793 63.4072L428.836 62.8506C428.921 62.8506 438.388 63.8354 445.756 56.4714L446.099 56.8567C440.144 62.8078 432.862 63.45 430.035 63.45Z"
                fill="#7FC064"/>
              <path
                d="M440.958 69.6152C437.617 69.0586 435.518 67.3889 434.319 66.0617C433.034 64.606 432.562 63.3216 432.52 63.236L432.948 63.0219C432.948 63.0647 434.876 68.0739 440.958 69.0586V69.6152Z"
                fill="#7FC064"/>
              <path
                d="M427.762 89.1811C420.523 89.1811 415.297 84.2147 415.254 84.1291L415.554 83.701C415.639 83.7438 422.108 89.9089 430.546 88.3677L430.632 88.9242C429.647 89.0955 428.704 89.1811 427.762 89.1811Z"
                fill="#7FC064"/>
              <path
                d="M420.608 87.3401L420.18 87.0404C421.636 84.4716 424.977 80.9609 432.816 79.1199C437.485 78.0067 441.555 78.0067 441.598 78.0067V78.5633C441.426 78.5633 425.448 78.6489 420.608 87.3401Z"
                fill="#7FC064"/>
              <path d="M446.909 120.735L281.049 122.362V112.557H445.41L446.909 120.735Z" fill="#7FC064"/>
              <path
                d="M13.7109 172.24L44.6813 298.497L47.8083 311.299L174.474 338.015L178.715 337.8C178.672 338.186 178.629 338.571 178.629 338.956V353.213C178.629 356.596 180.642 359.336 183.17 359.336H420.309C422.793 359.336 424.849 356.596 424.849 353.213C424.849 353.213 437.443 301.794 403.003 285.953C368.563 270.112 332.667 287.237 314.247 267.415C293.515 245.066 302.253 172.497 243.268 172.197L13.7109 172.24Z"
                fill="#4DB05B"/>
              <path
                d="M414.102 400.394C415.302 400.351 416.158 397.954 415.859 395.77L410.933 358.822H404.293L411.404 397.825C411.661 400.394 413.331 400.437 414.102 400.394Z"
                fill="#561C17"/>
              <path
                d="M204.891 400.394C206.091 400.351 206.948 397.954 206.648 395.77L201.722 358.822H195.082L202.193 397.825C202.493 400.394 204.163 400.437 204.891 400.394Z"
                fill="#561C17"/>
              <path
                d="M49.6095 400.394C48.41 400.351 47.5533 397.954 47.8532 395.77L52.7793 358.822H59.4189L52.3081 397.825C52.0083 400.394 50.3805 400.437 49.6095 400.394Z"
                fill="#561C17"/>
              <path
                d="M13.7109 172.411L44.6813 298.669L68.5408 309.329L128.168 282.143C128.168 282.143 232.902 282.442 302.51 282.399C293.044 253.415 302.296 172.668 243.268 172.411H13.7109Z"
                fill="#7FC064"/>
              <path
                d="M0.349063 193.475L26.1791 346.791C27.5927 355.226 33.2042 361.22 39.6295 361.134L181.63 359.336C181.63 359.336 192.982 301.794 158.542 285.953C124.102 270.112 86.0208 300.981 64.7742 291.776C46.0978 283.727 60.3193 194.974 17.3121 173.31C7.71683 168.472 -2.00691 180.032 0.349063 193.475Z"
                fill="#4BAE48"/>
              <path
                d="M47.4243 206.833C47.2101 206.833 46.9959 206.662 46.9531 206.362C43.1835 190.307 32.3032 179.818 32.1747 179.732C31.9606 179.518 31.8749 179.09 32.0462 178.79C32.2176 178.491 32.5174 178.405 32.7316 178.619C32.8601 178.705 43.9974 189.451 47.8955 205.934C47.9812 206.277 47.8527 206.662 47.5528 206.79C47.51 206.833 47.4671 206.833 47.4243 206.833Z"
                fill="#F4F4F4"/>
              <path
                d="M55.9063 239.158C55.6921 239.158 55.4779 238.986 55.4351 238.687L51.8797 223.188C51.794 222.846 51.9654 222.46 52.2224 222.332C52.4794 222.203 52.7793 222.417 52.8649 222.76L56.4203 238.258C56.506 238.601 56.3346 238.986 56.0776 239.115C55.9919 239.158 55.9491 239.158 55.9063 239.158Z"
                fill="#F4F4F4"/>
              <path
                d="M28.8748 195.274C28.6606 195.274 28.4464 195.145 28.3179 194.888C28.2323 194.717 19.7079 179.475 12.2545 181.231C5.10089 182.9 5.91478 192.148 5.95761 192.533C6.00045 193.047 5.74343 193.518 5.35791 193.604C4.97239 193.689 4.6297 193.304 4.58686 192.79C4.58686 192.662 3.47313 181.402 11.9975 179.39C15.51 178.576 19.5366 180.76 23.9058 185.94C27.1185 189.708 29.3032 193.647 29.3888 193.818C29.603 194.246 29.5602 194.803 29.2603 195.102C29.1746 195.231 29.0033 195.274 28.8748 195.274Z"
                fill="#F4F4F4"/>
              <path
                d="M108.125 85.3706C108.125 87.597 91.3329 89.4379 70.5575 89.4379C49.825 89.4379 33.0762 92.6918 33.0762 90.4227C33.0762 74.3675 49.825 59.2971 70.5575 59.2971C91.2901 59.3399 108.125 70.9852 108.125 85.3706Z"
                fill="#7FC064"/>
              <path
                d="M108.04 85.6275C108.126 87.2116 100.03 89.0954 88.0784 90.5511C87.3931 90.6367 86.6648 90.7223 85.9795 90.808C81.3532 91.3217 76.2129 91.7927 70.8584 92.1352C66.275 92.4349 61.8629 92.6061 57.7935 92.7346C56.3371 92.7774 54.8807 92.7774 53.5099 92.8202C41.473 92.9058 33.2057 92.0924 33.12 90.5083C33.0344 89.1382 39.0742 87.5541 48.4124 86.2269C49.1406 86.1413 49.9117 86.0128 50.6827 85.9272C54.6236 85.4134 59.0786 84.9425 63.8333 84.5143C65.9323 84.3431 68.1169 84.1718 70.3016 84.0434C71.8436 83.9578 73.3857 83.8721 74.8422 83.7865C81.139 83.444 87.0075 83.3584 91.9765 83.4012C92.105 83.4012 92.2335 83.4012 92.3192 83.4012C101.786 83.444 107.954 84.2575 108.04 85.6275Z"
                fill="#4DB05B"/>
              <path
                d="M91.9753 83.3156C90.8615 85.4134 89.4908 87.2972 87.863 88.967C87.2633 89.6092 86.6208 90.1658 85.9354 90.7223C81.7375 94.1902 76.383 96.2881 70.5145 96.2881C65.8454 96.2881 61.4333 94.9609 57.7494 92.6061C56.0788 91.5786 54.5796 90.337 53.2088 88.9242C52.2664 87.9394 51.4097 86.9119 50.6387 85.7988C54.5796 85.285 59.0345 84.814 63.7893 84.3859C65.8882 84.2147 68.0729 84.0434 70.2575 83.915C71.7996 83.8293 73.3417 83.7437 74.7981 83.6581C81.1806 83.4012 87.0063 83.2727 91.9753 83.3156Z"
                fill="white"/>
              <path
                d="M70.5575 62.0371C69.6579 62.0371 68.9297 61.3093 68.9297 60.4102V33.8657C68.9297 32.9666 69.6579 32.2388 70.5575 32.2388C71.457 32.2388 72.1852 32.9666 72.1852 33.8657V60.453C72.1852 61.3093 71.457 62.0371 70.5575 62.0371Z"
                fill="#7FC064"/>
              <path
                d="M70.5578 64.7344C73.1365 64.7344 75.2269 62.6259 75.2269 60.0249C75.2269 57.4239 73.1365 55.3154 70.5578 55.3154C67.9791 55.3154 65.8887 57.4239 65.8887 60.0249C65.8887 62.6259 67.9791 64.7344 70.5578 64.7344Z"
                fill="#7FC064"/>
              <path
                d="M305.982 393.458C305.982 393.458 305.768 400.994 310.737 403.391C315.706 405.789 330.399 408.529 324.616 413.067C321.275 415.679 306.454 413.067 302.256 412.853C298.058 412.639 293.988 414.223 290.99 411.269C287.691 407.972 292.018 392.73 292.018 392.73C292.018 392.73 295.573 394.828 298.829 394.914C303.498 395.042 305.982 393.458 305.982 393.458Z"
                fill="#561C17"/>
              <path
                d="M351.904 393.416C351.904 393.416 351.69 400.951 356.659 403.348C361.628 405.746 376.321 408.486 370.538 413.024C367.197 415.636 352.375 413.024 348.178 412.81C343.98 412.596 339.91 414.18 336.912 411.226C333.613 407.929 337.94 392.688 337.94 392.688C337.94 392.688 341.495 394.786 344.751 394.871C349.463 395 351.904 393.416 351.904 393.416Z"
                fill="#561C17"/>
              <path
                d="M357.341 380.957C353.614 331.507 352.714 298.155 337.079 276.748C319.945 253.286 274.025 236.717 256.334 235.69C227.462 234.02 213.84 238.301 213.84 238.301C192.166 283.341 226.134 289.335 251.236 290.577C252.05 290.62 252.907 290.663 253.721 290.705C268.927 291.647 295.957 295.543 306.195 310.614C314.291 322.559 319.902 339.342 322.301 382.669C322.772 390.975 329.711 397.44 338.064 397.183C338.064 397.14 358.711 398.724 357.341 380.957Z"
                fill="#FFC655"/>
              <path
                d="M316.39 383.483C316.861 333.904 318.403 308.601 303.539 286.638C293.987 272.51 279.594 261.892 260.789 254.956C247.382 250.032 231.575 246.993 213.884 245.965C185.013 244.295 172.033 240.785 172.033 240.785C148.645 298.455 234.145 286.21 256.42 310.785C266.101 321.488 278.138 341.868 280.494 385.152C280.965 393.458 287.904 399.923 296.257 399.666C296.214 399.709 316.219 401.636 316.39 383.483Z"
                fill="#FFC655"/>
              <path
                d="M316.218 334.803C316.218 334.504 314.718 305.091 309.792 294.815C303.538 281.8 285.461 268.228 266.742 262.534L266.828 262.234C285.633 267.971 303.795 281.586 310.092 294.687C315.061 305.005 316.517 334.504 316.517 334.803H316.218Z"
                fill="#020202"/>
              <path
                d="M245.326 140.087C246.14 140.558 259.59 146.594 262.503 168.815C265.416 191.035 247.082 170.998 247.082 170.998L245.326 140.087Z"
                fill="#FFA076"/>
              <path
                d="M177.131 146.937C177.131 146.937 184.884 125.145 212.471 126.172C239.457 127.157 265.33 159.053 271.284 233.378C271.541 236.418 269.956 239.372 267.258 240.827C253.722 248.106 211.1 268.528 168.778 261.72C162.524 260.693 158.155 254.999 158.84 248.705C161.239 225.757 167.707 171.598 177.131 146.937Z"
                fill="#00A76E"/>
              <path
                d="M157.771 145.61L177.305 158.111C177.305 158.111 227.551 163.12 240.873 160.723C254.195 158.325 256.294 148.521 256.294 148.521C256.294 148.521 243.615 131.139 219.455 126.814C217.185 126.386 214.915 126.343 212.473 126.172C208.361 125.872 204.291 126.001 200.265 126.429C176.405 128.955 157.771 145.61 157.771 145.61Z"
                fill="#00A76E"/>
              <path
                d="M231.018 126.9V126.6C231.06 126.6 234.659 126.386 236.072 124.203C236.8 123.09 236.8 121.591 236.115 119.836C235.772 119.022 235.473 118.252 235.215 117.567C233.288 112.771 232.56 111.016 233.93 107.634L234.23 107.762C232.902 111.016 233.588 112.643 235.515 117.481C235.815 118.166 236.115 118.937 236.415 119.75C237.143 121.634 237.143 123.218 236.329 124.417C234.83 126.643 231.189 126.9 231.018 126.9Z"
                fill="#561C17"/>
              <path
                d="M234.057 107.591C234.057 107.591 231.873 112.814 234.486 119.151C237.056 125.487 232.387 128.955 227.889 125.872C223.391 122.79 220.435 128.698 214.781 124.16C209.17 119.622 234.057 107.591 234.057 107.591Z"
                fill="#561C17"/>
              <path
                d="M207.798 123.132L212.553 115.94L213.923 113.842L222.062 101.554L218.592 115.212L216.494 123.389C216.494 123.389 216.579 126.001 218.421 126.686C218.421 126.686 223.519 133.836 214.352 134.521C209.083 134.906 202.615 131.567 200.559 126.429C202.615 125.787 206.256 125.444 207.798 123.132Z"
                fill="#FFA076"/>
              <path
                d="M212.6 115.683C212.6 115.768 212.6 115.854 212.557 115.897C212.086 120.392 216.498 123.346 216.498 123.346H216.54L218.639 115.169C217.269 114.356 215.298 113.456 213.97 113.842C213.242 114.013 212.728 114.57 212.6 115.683Z"
                fill="#561C17"/>
              <path
                d="M235.301 106.178C236.886 97.3157 232.346 89.1382 225.192 87.8538C218.039 86.5694 210.928 92.6918 209.343 101.554C207.758 110.417 212.299 118.594 219.452 119.879C226.606 121.12 233.717 114.998 235.301 106.178Z"
                fill="#FFA076"/>
              <path
                d="M210.627 104.251C211.827 105.921 213.883 106.435 215.168 105.407C216.453 104.38 216.496 102.196 215.296 100.527C214.097 98.857 212.041 98.3432 210.756 99.3707C209.471 100.398 209.385 102.582 210.627 104.251Z"
                fill="#FFA076"/>
              <path
                d="M227.419 115.469C227.419 115.469 222.964 114.784 218.809 112.429C218.809 112.429 217.653 116.753 222.408 117.866C225.192 118.551 227.419 115.469 227.419 115.469Z"
                fill="#DB6B49"/>
              <path
                d="M227.419 115.469C227.419 115.469 226.176 116.154 223.692 115.726C221.207 115.255 218.809 112.429 218.809 112.429C218.809 112.429 223.906 114.827 227.419 115.469Z"
                fill="white"/>
              <path
                d="M223.523 104.209C223.608 104.209 223.694 104.166 223.737 104.08C223.822 103.952 223.78 103.738 223.651 103.652C223.565 103.609 221.766 102.239 219.667 103.823C219.539 103.909 219.496 104.123 219.582 104.252C219.667 104.38 219.839 104.38 220.01 104.294C221.509 103.609 223.223 104.123 223.308 104.166C223.394 104.209 223.48 104.209 223.523 104.209Z"
                fill="#561C17"/>
              <path
                d="M220.609 107.034C220.48 107.719 220.737 108.404 221.251 108.49C221.765 108.618 222.28 108.105 222.451 107.42C222.579 106.735 222.322 106.05 221.808 105.964C221.294 105.878 220.737 106.349 220.609 107.034Z"
                fill="#561C17"/>
              <path
                d="M233.802 107.634C233.888 107.677 233.974 107.634 234.06 107.591C234.188 107.505 234.231 107.291 234.145 107.163C234.102 107.077 232.946 105.108 230.418 105.793C230.247 105.836 230.161 106.007 230.204 106.135C230.247 106.307 230.418 106.349 230.547 106.349C232.218 106.307 233.588 107.42 233.674 107.463C233.674 107.591 233.717 107.634 233.802 107.634Z"
                fill="#561C17"/>
              <path
                d="M230.032 109.132C229.647 109.732 229.647 110.46 230.075 110.716C230.503 111.016 231.189 110.759 231.574 110.16C231.96 109.56 231.96 108.833 231.531 108.576C231.103 108.319 230.418 108.576 230.032 109.132Z"
                fill="#561C17"/>
              <path
                d="M230.161 91.7071C230.161 91.7071 228.876 97.2301 221.765 96.9304C214.654 96.6307 209.343 98.7285 209.386 103.866C209.429 109.047 218.767 121.42 209.814 123.946C200.861 126.515 203.946 119.322 194.736 119.45C185.526 119.579 186.04 107.42 193.408 101.597C200.776 95.7744 201.547 77.0648 224.421 81.9884C247.295 86.9119 235.601 103.781 235.601 103.781C235.601 103.781 229.047 97.8294 230.161 91.7071Z"
                fill="#561C17"/>
              <path
                d="M207.287 124.588L207.244 124.417C209.086 124.031 210.242 123.261 210.842 121.976C212.47 118.509 209.172 112.172 206.259 106.563C205.488 105.065 204.759 103.652 204.117 102.325C202.361 98.6001 202.832 94.6613 205.402 92.0068C208.229 89.0527 212.77 88.3676 217.182 90.2942C221.465 92.1352 224.721 91.7499 226.649 91.1077C228.747 90.4227 229.904 89.2667 229.904 89.2667L230.033 89.3952C229.99 89.438 225.406 94.019 217.096 90.4655C212.727 88.5817 208.315 89.2239 205.53 92.1352C203.003 94.7897 202.532 98.5573 204.288 102.239C204.931 103.567 205.659 104.979 206.43 106.478C209.386 112.129 212.727 118.551 211.056 122.062C210.371 123.346 209.129 124.16 207.287 124.588Z"
                fill="white"/>
              <path
                d="M231.021 117.995C230.335 117.866 229.693 117.567 229.136 117.053C226.652 114.826 226.523 109.389 228.879 104.937C230.293 102.239 232.434 100.355 234.49 100.013C235.733 99.7989 236.889 100.099 237.789 100.955C240.273 103.181 240.402 108.618 238.046 113.028C236.632 115.768 234.49 117.609 232.434 117.952C231.963 118.038 231.492 118.038 231.021 117.995ZM229.393 105.236C227.166 109.432 227.251 114.57 229.522 116.625C230.293 117.31 231.278 117.567 232.349 117.395C234.233 117.053 236.204 115.297 237.532 112.771C239.759 108.576 239.716 103.438 237.403 101.383C236.632 100.698 235.647 100.398 234.576 100.612C232.691 100.912 230.764 102.667 229.393 105.236Z"
                fill="#00A76E"/>
              <g opacity="0.3">
                <path
                  d="M214.398 99.1995C212.556 103.566 213.327 108.79 216.154 110.845C218.982 112.9 222.794 110.973 224.636 106.606C226.478 102.239 225.707 97.016 222.88 94.9609C220.052 92.9059 216.24 94.8325 214.398 99.1995Z"
                  fill="white"/>
              </g>
              <g opacity="0.3">
                <path
                  d="M228.66 105.322C226.775 109.732 227.289 114.955 229.773 117.01C232.258 119.065 235.77 117.138 237.655 112.729C239.54 108.319 239.026 103.096 236.541 101.04C234.057 98.9854 230.544 100.912 228.66 105.322Z"
                  fill="white"/>
              </g>
              <path
                d="M218.251 111.787C217.566 111.658 216.88 111.359 216.28 110.93C213.153 108.747 212.254 103.138 214.267 98.4288C215.424 95.6887 217.351 93.8049 219.493 93.1627C220.992 92.7346 222.449 92.9915 223.734 93.8906C226.861 96.1169 227.76 101.725 225.747 106.392C224.591 109.132 222.663 111.016 220.478 111.658C219.707 111.872 218.936 111.915 218.251 111.787ZM221.592 93.6337C220.95 93.5052 220.307 93.5481 219.622 93.7621C217.651 94.3187 215.852 96.1597 214.738 98.6857C212.854 103.138 213.667 108.404 216.58 110.459C217.694 111.23 218.979 111.444 220.307 111.102C222.277 110.545 224.119 108.704 225.19 106.178C227.075 101.725 226.261 96.4594 223.348 94.4043C222.792 94.019 222.235 93.7621 221.592 93.6337Z"
                fill="#00A76E"/>
              <path
                d="M229.005 107.12C228.877 107.077 228.791 107.034 228.705 106.949C228.663 106.906 227.463 105.536 224.936 105.579C224.636 105.579 224.379 105.322 224.379 105.022C224.379 104.722 224.636 104.466 224.936 104.466C227.977 104.38 229.476 106.135 229.562 106.221C229.733 106.435 229.733 106.82 229.476 106.992C229.305 107.12 229.134 107.163 229.005 107.12Z"
                fill="#00A76E"/>
              <path
                d="M157.47 146.038C156.785 148.007 161.882 151.689 168.864 154.258C175.847 156.827 182.058 157.341 182.743 155.371C183.428 153.402 178.331 149.72 171.349 147.151C164.367 144.582 158.155 144.068 157.47 146.038Z"
                fill="#561C17"/>
              <path
                d="M205.403 214.069L205.617 199.812C205.66 197.842 204.161 196.215 202.19 196.087C192.295 195.359 185.356 192.319 181.586 186.925C176.318 179.475 176.874 167.916 178.331 159.867C179.102 155.756 177.731 153.016 172.762 150.362C172.762 150.362 168.479 148.007 163.21 147.108C161.111 146.766 159.012 147.579 158.455 149.677C157.598 152.931 156.485 157.94 155.97 163.891C154.728 177.977 157.427 190.221 163.809 199.298C171.648 210.472 184.285 216.638 201.419 217.665C203.561 217.794 205.36 216.167 205.403 214.069Z"
                fill="#FFA076"/>
              <g opacity="0.5">
                <path
                  d="M168.992 195.573C168.607 195.573 168.221 195.53 167.793 195.402C166.679 195.059 165.608 194.332 164.794 193.261C163.98 192.234 163.466 191.035 163.338 189.879C163.252 188.68 163.638 187.653 164.409 187.01C165.951 185.769 168.65 186.54 170.449 188.723C172.205 190.907 172.376 193.732 170.834 194.974C170.32 195.359 169.678 195.573 168.992 195.573ZM166.251 187.396C165.78 187.396 165.351 187.524 165.051 187.781C164.537 188.209 164.28 188.937 164.366 189.836C164.452 190.778 164.88 191.806 165.608 192.705C166.336 193.604 167.236 194.246 168.136 194.503C168.992 194.76 169.763 194.674 170.277 194.246C171.391 193.347 171.177 191.078 169.763 189.365C168.735 188.081 167.364 187.396 166.251 187.396Z"
                  fill="#F47C53"/>
              </g>
              <path
                d="M258.303 204.222H209.899C206.857 204.222 204.93 201.738 205.658 198.699L212.426 169.286C213.111 166.246 216.196 163.763 219.237 163.763H267.641C270.683 163.763 272.61 166.246 271.882 169.286L265.114 198.699C264.429 201.738 261.344 204.222 258.303 204.222Z"
                fill="#8A4D27"/>
              <path
                d="M254.319 204.222H205.914C202.873 204.222 200.945 201.738 201.674 198.699L208.442 169.286C209.127 166.246 212.211 163.763 215.252 163.763H263.657C266.698 163.763 268.626 166.246 267.898 169.286L261.13 198.699C260.444 201.738 257.36 204.222 254.319 204.222Z"
                fill="#8A4D27"/>
              <path
                d="M242.371 183.971C242.029 185.555 240.015 186.754 238.473 186.754C236.888 186.754 235.903 185.469 236.289 183.928C236.631 182.344 238.859 180.717 240.444 180.717C241.986 180.717 242.714 182.387 242.371 183.971Z"
                fill="white"/>
              <path
                d="M209.94 183.457L209.555 183.371C209.683 182.943 212.468 172.497 214.352 169.114C216.237 165.689 218.593 165.004 218.679 165.004L218.764 165.39C218.722 165.39 216.494 166.075 214.652 169.328C212.81 172.625 209.94 183.371 209.94 183.457Z"
                fill="#FFC655"/>
              <path
                d="M208.314 203.451C205.615 202.809 206.343 199.212 206.343 199.17L208.871 187.952L209.256 188.038L206.729 199.255C206.686 199.384 206.086 202.552 208.442 203.108L208.314 203.451Z"
                fill="#FFC655"/>
              <path
                d="M198.596 195.145C198.596 195.145 202.065 184.613 208.02 182.044C213.974 179.475 223.526 180.674 220.185 183.072C216.844 185.469 211.703 187.31 211.703 187.31C211.703 187.31 222.841 183.842 222.498 186.796C222.198 189.751 212.475 192.662 212.475 192.662C212.475 192.662 226.053 189.622 224.854 192.277C223.655 194.974 213.331 197.2 213.331 197.2C213.331 197.2 226.053 194.845 224.597 197.971C223.141 201.053 214.059 199.855 213.717 202.809C213.417 205.763 211.147 217.365 202.836 217.494L198.596 195.145Z"
                fill="#FFA076"/>
              <path
                d="M207.715 189.751L207.287 189.536C208.187 187.61 211.528 187.096 211.656 187.053L211.742 187.524C211.699 187.567 208.529 188.081 207.715 189.751Z"
                fill="#F47C53"/>
              <path
                d="M207.673 195.616L207.287 195.316C209.086 192.919 212.299 192.448 212.427 192.448L212.47 192.919C212.47 192.919 209.343 193.39 207.673 195.616Z"
                fill="#F47C53"/>
              <path
                d="M208.831 199.983L208.402 199.812C209.216 197.671 213.114 197.072 213.286 197.029L213.371 197.5C213.328 197.5 209.559 198.099 208.831 199.983Z"
                fill="#F47C53"/>
              <path
                d="M273.382 187.053C273.382 187.053 274.153 193.176 268.97 193.732C262.373 194.46 253.378 188.595 257.533 188.038C261.217 187.567 266.657 188.252 266.657 188.252C266.657 188.252 252.864 186.283 254.149 183.371C255.434 180.46 267.257 182.301 267.257 182.301C267.257 182.301 253.978 179.047 256.034 176.735C258.09 174.423 268.842 176.007 268.842 176.007C268.842 176.007 255.734 173.567 258.176 170.912C260.617 168.258 267.214 170.998 270.212 171.512C273.082 171.983 273.768 174.081 273.211 176.992C273.211 176.992 274.239 180.289 273.468 182.087C273.168 182.772 273.725 183.585 273.725 184.142C273.725 185.169 273.382 187.053 273.382 187.053Z"
                fill="#FFA076"/>
              <path
                d="M175.333 381C175.333 381 174.733 382.37 173.491 384.596C173.448 384.639 173.405 384.724 173.405 384.767C171.178 388.663 166.98 395.128 160.212 401.721C157.899 403.99 154.772 405.746 151.131 407.03L151.045 407.073C150.274 407.33 149.46 407.63 148.646 407.844C136.824 411.226 120.632 410.498 107.909 406.816L105.725 406.131C104.654 405.789 103.583 405.403 102.555 405.018C99.2566 403.734 96.6008 402.107 94.4162 400.309C94.1592 400.094 93.8593 399.838 93.6023 399.624C84.7781 391.703 84.9495 381 84.9495 381H175.333V381Z"
                fill="#00A76E"/>
              <path
                d="M178.244 364.816V376.547C178.244 383.782 156.441 389.691 129.497 389.691C102.596 389.691 80.75 383.825 80.75 376.547V364.816C80.75 361.134 93.3009 357.966 111.121 356.681C116.775 356.253 122.986 356.039 129.454 356.039C156.441 356.039 178.244 359.978 178.244 364.816Z"
                fill="#FFC655"/>
              <path
                d="M178.244 364.816C178.244 365.544 177.773 366.229 176.916 366.871C171.819 370.724 152.543 373.636 129.497 373.636C123.072 373.636 116.903 373.422 111.249 372.993C93.3866 371.709 80.75 368.541 80.75 364.859C80.75 361.177 93.3009 358.009 111.121 356.724C116.775 356.296 122.986 356.082 129.454 356.082C156.441 356.039 178.244 359.978 178.244 364.816Z"
                fill="#00613E"/>
              <path
                d="M125.772 247.335C121.103 250.118 118.832 249.433 117.761 247.592C116.691 245.751 117.205 243.439 121.874 240.656C130.398 235.604 136.438 236.503 136.438 236.503C136.438 236.503 134.425 242.24 125.772 247.335Z"
                fill="#561C17"/>
              <path
                d="M121.402 279.06C116.904 282.143 114.591 281.629 113.434 279.873C112.235 278.118 112.62 275.763 117.075 272.681C125.257 267.029 131.382 267.5 131.382 267.5C131.382 267.5 129.712 273.323 121.402 279.06Z"
                fill="#561C17"/>
              <path
                d="M130.055 371.11L129.027 371.024C129.027 370.853 130.312 354.84 125 331.378C120.074 309.715 108.337 277.133 80.4082 244.766L81.1792 244.081C109.28 276.62 121.059 309.415 125.986 331.164C131.383 354.798 130.055 370.981 130.055 371.11Z"
                fill="#561C17"/>
              <path
                d="M104.82 278.161L104.221 277.776C117.971 256.112 109.704 229.781 109.618 229.524L110.303 229.31C110.346 229.61 118.742 256.197 104.82 278.161Z"
                fill="#561C17"/>
              <path
                d="M93.2145 268.142C82.8054 268.142 74.9236 262.876 74.8379 262.834L75.3948 261.977C75.4804 262.063 85.5897 268.785 97.9264 266.73L98.0978 267.757C96.4272 268.014 94.7994 268.142 93.2145 268.142Z"
                fill="#561C17"/>
              <path
                d="M128.298 347.776C107.223 345.336 90.1317 334.975 77.538 316.95C77.1096 316.308 76.6812 315.751 76.5527 315.623C76.5527 315.623 76.5956 315.666 76.6384 315.666L76.9811 314.681C77.2809 314.767 77.4523 315.024 78.3518 316.351C90.7742 334.118 107.609 344.351 128.384 346.749L128.298 347.776Z"
                fill="#561C17"/>
              <path
                d="M98.529 336.644L97.5438 336.388C101.185 322.816 92.2322 302.864 92.1465 302.693L93.0889 302.265C93.1317 302.308 95.402 307.403 97.2439 314.167C98.8717 320.375 100.457 329.323 98.529 336.644Z"
                fill="#561C17"/>
              <path
                d="M79.7218 239.029C85.162 243.953 85.6332 246.736 84.1768 248.32C82.7632 249.904 79.9788 249.733 74.5387 244.809C64.6436 235.818 62.459 228.583 62.459 228.583C62.459 228.583 69.6982 229.867 79.7218 239.029Z"
                fill="#561C17"/>
              <path
                d="M77.5799 259.494C84.2195 262.534 85.5045 265.06 84.605 266.987C83.7054 268.956 81.0496 269.598 74.3672 266.558C62.2447 260.993 58.0039 254.742 58.0039 254.742C58.0039 254.742 65.286 253.843 77.5799 259.494Z"
                fill="#561C17"/>
              <path
                d="M95.1864 299.268C97.6709 304.149 96.857 306.375 94.9722 307.36C93.0874 308.345 90.8171 307.702 88.3326 302.822C83.792 293.959 85.0343 287.88 85.0343 287.88C85.0343 287.88 90.5601 290.277 95.1864 299.268Z"
                fill="#561C17"/>
              <path
                d="M103.709 251.659C104.951 256.968 103.623 258.98 101.567 259.451C99.5112 259.922 97.4551 258.766 96.2129 253.415C93.9426 243.696 96.5984 238.13 96.5984 238.13C96.5984 238.13 101.439 241.812 103.709 251.659Z"
                fill="#561C17"/>
              <path
                d="M112.104 331.164C111.804 336.644 110.005 338.143 107.863 338.057C105.721 337.929 104.093 336.216 104.393 330.736C104.95 320.803 109.019 316.18 109.019 316.18C109.019 316.18 112.618 321.06 112.104 331.164Z"
                fill="#561C17"/>
              <path
                d="M82.165 316.051C85.6347 320.247 85.3348 322.644 83.7071 324.014C82.0793 325.384 79.7233 325.256 76.2536 321.017C69.9568 313.354 69.8711 307.146 69.8711 307.146C69.8711 307.146 75.7824 308.259 82.165 316.051Z"
                fill="#561C17"/>
              <path
                d="M111.723 226.442C112.879 231.366 112.108 233.078 110.694 233.421C109.281 233.763 107.782 232.564 106.625 227.684C104.483 218.735 106.025 213.812 106.025 213.812C106.025 213.812 109.581 217.365 111.723 226.442Z"
                fill="#561C17"/>
              <path
                d="M130.783 372.522C130.783 372.522 104.053 271.824 77.2377 280.173C50.4225 288.522 109.964 343.623 116.818 373.207L130.783 372.522Z"
                fill="#00A76E"/>
              <path
                d="M126.842 367.941C126.842 367.941 85.9339 287.152 60.4037 299.225C34.9164 311.299 102.04 347.219 113.049 371.11L126.842 367.941Z"
                fill="#B8E34D"/>
              <path
                d="M118.96 369.868C104.996 338.057 68.114 310.314 67.7285 310.057L68.4139 309.158C68.7994 309.458 105.895 337.372 120.031 369.44L118.96 369.868Z"
                fill="#F4F4F4"/>
              <path
                d="M211.484 298.455C211.27 299.011 211.013 299.611 210.756 300.167C209.085 303.935 206.858 307.617 204.373 310.828C201.632 314.424 201.032 318.32 201.246 322.259C201.246 322.388 201.246 322.473 201.246 322.602C201.589 328.467 203.602 334.333 202.488 339.299C202.403 339.641 202.317 339.984 202.189 340.326C201.332 343.067 199.361 345.464 195.42 347.434C182.313 353.898 180.214 353.385 179.057 359.036C178.972 359.379 178.929 359.721 178.843 360.106C178.672 361.177 178.5 362.461 178.286 364.002C178.286 364.002 178.543 365.715 176.873 366.871C172.332 370.296 156.568 372.951 136.95 373.507C136.607 373.507 136.221 373.507 135.879 373.55C133.78 373.593 131.681 373.636 129.496 373.636C123.028 373.636 116.902 373.421 111.248 372.993C109.834 369.354 108.507 363.36 111.12 356.681C112.062 354.241 113.561 351.672 115.789 349.146C127.526 335.617 136.95 338.015 134.765 314.082C134.722 313.825 134.722 313.525 134.679 313.268C134.636 313.054 134.636 312.797 134.594 312.583C131.852 287.023 166.078 298.069 174.602 289.464C183.169 280.858 188.952 271.182 204.373 274.95C215.211 277.604 215.553 288.136 211.484 298.455Z"
                fill="#B8E34D"/>
              <path
                d="M209.044 277.904C196.151 289.292 185.485 300.296 176.618 310.528C176.318 310.871 176.061 311.213 175.761 311.513C162.782 326.626 153.872 339.984 147.832 350.516C147.661 350.816 147.489 351.116 147.318 351.415C140.807 362.932 137.851 370.81 136.952 373.507C136.609 373.507 136.224 373.507 135.881 373.55C137.123 369.825 143.72 351.586 162.653 326.198C162.867 325.898 163.082 325.641 163.296 325.342C174.048 311.085 188.655 294.559 208.359 277.133L209.044 277.904Z"
                fill="#F4F4F4"/>
              <path
                d="M154.772 319.048C140.765 318.534 134.554 313.953 134.297 313.782L134.897 312.968C134.939 313.011 141.108 317.55 154.772 318.063V319.048Z"
                fill="#F4F4F4"/>
              <path
                d="M163.256 326.455C157.688 324.357 153.147 318.406 150.063 309.287C147.75 302.436 147.064 296.143 147.064 296.057L148.093 295.929C148.135 296.185 150.834 320.632 163.599 325.47L163.256 326.455Z"
                fill="#F4F4F4"/>
              <path
                d="M178.074 311.556C176.446 311.556 175.504 311.47 175.461 311.47L175.547 310.442C175.761 310.442 195.722 312.283 211.315 298.283L212 299.054C199.449 310.357 184.114 311.556 178.074 311.556Z"
                fill="#F4F4F4"/>
              <path
                d="M201.164 323.287C194.096 322.259 189.641 319.048 187.156 316.479C184.458 313.739 183.43 311.256 183.387 311.127L184.329 310.742C184.372 310.828 188.356 320.375 201.292 322.259L201.164 323.287Z"
                fill="#F4F4F4"/>
              <path
                d="M173.322 360.577C158.03 360.577 146.978 351.116 146.85 350.987L147.535 350.216C147.663 350.345 161.414 362.033 179.234 359.079L179.405 360.106C177.263 360.406 175.25 360.577 173.322 360.577Z"
                fill="#F4F4F4"/>
              <path
                d="M158.156 357.067L157.299 356.51C160.34 351.586 167.451 344.95 183.986 341.397C193.838 339.299 202.491 339.256 202.576 339.256V340.284C202.234 340.326 168.393 340.541 158.156 357.067Z"
                fill="#F4F4F4"/>
              <path
                d="M107.864 406.902C112.491 406.516 117.888 405.874 123.628 404.932C124.399 404.804 125.17 404.676 125.984 404.547C142.133 401.636 160.681 395.942 173.403 384.853C173.446 384.81 173.532 384.767 173.575 384.724L173.532 384.681L172.846 384.125C154.47 400.266 123.5 404.932 105.723 406.26L107.864 406.902Z"
                fill="#FFC655"/>
              <path
                d="M148.6 407.887C148.515 407.887 148.429 407.887 148.343 407.887C141.661 407.672 133.394 406.859 123.627 404.89L123.841 404.076C124.569 404.205 125.255 404.376 125.94 404.504C136.092 406.431 144.488 407.03 151.085 407.116L148.6 407.887Z"
                fill="#FFC655"/>
              <path
                d="M141.875 381C133.094 387.507 118.316 395.299 94.3276 400.351C94.0705 400.137 93.7707 399.88 93.5137 399.666C95.784 399.195 97.9686 398.682 100.068 398.168C101.138 397.911 102.167 397.654 103.195 397.354C121.014 392.645 132.794 386.394 140.29 381H141.875Z"
                fill="#FFC655"/>
              <path
                d="M116.903 381C114.118 386.565 109.107 393.244 100.111 398.168C100.068 398.168 100.068 398.211 100.025 398.211L100.582 398.896C101.482 398.382 102.381 397.868 103.195 397.354C110.991 392.431 115.446 386.18 117.974 381H116.903Z"
                fill="#FFC655"/>
              <path
                d="M458.222 427.538C458.222 427.538 513.78 436.144 560.386 431.605C606.991 427.024 671.374 424.541 659.636 347.733C647.899 270.968 660.579 238.858 614.573 219.977C568.567 201.096 515.023 213.341 479.169 257.225C443.273 301.195 359.186 394.7 458.222 427.538Z"
                fill="#00A76E"/>
              <path
                d="M418.727 411.868C418.727 411.868 418.384 419.703 413.029 421.801C407.675 423.899 392.297 425.569 397.908 430.706C401.121 433.66 416.713 432.119 421.083 432.205C425.452 432.29 429.521 434.303 432.862 431.434C436.503 428.266 433.248 412.168 433.248 412.168C433.248 412.168 429.436 414.052 426.009 413.88C421.125 413.666 418.727 411.868 418.727 411.868Z"
                fill="#561C17"/>
              <path
                d="M371.992 407.373C371.992 407.373 371.564 415.208 366.166 417.22C360.812 419.275 345.391 420.774 350.96 425.997C354.172 428.994 369.722 427.624 374.091 427.752C378.46 427.881 382.53 429.936 385.871 427.11C389.555 423.985 386.471 407.844 386.471 407.844C386.471 407.844 382.615 409.685 379.231 409.514C374.391 409.214 371.992 407.373 371.992 407.373Z"
                fill="#561C17"/>
              <path
                d="M375.544 413.838C366.505 412.81 359.866 404.847 360.465 395.77C363.978 344.437 368.776 318.62 386.124 297.213C397.261 283.47 413.539 270.454 433.072 263.305C449.907 257.182 464.085 254.571 482.462 255.084C512.447 255.898 526.154 261.549 526.154 261.549C544.531 310.057 508.934 313.183 482.847 312.241C481.991 312.198 481.134 312.198 480.234 312.155C464.428 311.77 436.156 313.354 424.205 328.082C414.781 339.727 407.456 356.553 401.159 401.122C399.96 409.685 392.164 415.721 383.597 414.737L375.544 413.838Z"
                fill="#8A4D27"/>
              <path
                d="M418.598 420.217C409.56 419.189 402.92 411.226 403.52 402.15C407.033 350.816 411.83 324.999 429.179 303.592C440.316 289.849 456.122 280.13 476.17 274.65C490.477 270.754 507.054 269.042 525.474 269.555C555.459 270.369 569.209 267.928 569.209 267.928C588.228 329.623 500.972 309.372 475.741 332.748C464.775 342.938 450.511 362.932 444.214 407.458C443.015 416.021 435.219 422.058 426.651 421.073L418.598 420.217Z"
                fill="#8A4D27"/>
              <path
                d="M505.34 271.568L447.512 269.983C446.312 269.941 445.241 269.17 444.899 268.014L433.847 231.794C433.29 229.953 434.704 228.154 436.631 228.197L492.232 229.696C494.76 229.781 496.987 231.451 497.715 233.849L508.124 267.928C508.638 269.812 507.225 271.653 505.34 271.568Z"
                fill="#7FC064"/>
              <path
                d="M501.701 271.482L443.873 269.898C442.674 269.855 441.603 269.084 441.26 267.928L430.208 231.708C429.652 229.867 431.065 228.069 432.993 228.112L488.594 229.61C491.121 229.696 493.349 231.365 494.077 233.763L504.486 267.843C505 269.727 503.629 271.525 501.701 271.482Z"
                fill="#7FC064"/>
              <path
                d="M523.119 266.987L444.002 264.846L443.873 269.898L524.662 272.124C525.604 272.167 526.375 271.396 526.418 270.454V270.412C526.418 268.571 524.961 267.029 523.119 266.987Z"
                fill="#7FC064"/>
              <path
                d="M405.787 373.293C405.787 373.079 405.83 353.513 413.112 332.492C417.396 320.118 423.307 309.715 430.675 301.494C439.884 291.219 451.45 284.369 464.986 281.201L465.072 281.543C451.579 284.711 440.099 291.519 430.932 301.751C423.607 309.929 417.695 320.332 413.455 332.663C406.173 353.642 406.173 373.165 406.173 373.379L405.787 373.293Z"
                fill="#020202"/>
            </svg>
          </div>
        </div>

      </div>
      <div class="heading-content col-md-5  ">
        <div class="heading-title "> {{ $t('search-roommates-title') }}</div>
        <div class="heading-subtitle "> {{ $t('search-roommates-subtitle') }}</div>
        <p class="heading-description ">
          {{ $t('search-roommates-description') }}
        </p>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <Button
              @click="$store.state.user.authenticated
                ? $router.push({name: 'AddRoommate'})
                : $store.dispatch('utils/openModal', 'login')"
              :text="$t('publish-new-offer')" :size="'medium'" :theme="'solid-orange'"/>
          </div>
        </div>
      </div>

    </div>
    <div ref="resultContainer" class="roommate-ad-list container">
      <div class="row  justify-content-center">
        <div class="ad-list-title col-md-9">{{ $t('all-offers') }}</div>
        <div class="ad-filters col-md-9">
          <div class="row justify-content-center">
            <div class="col-md-3 filter-item">
              <Dropdown :label="$t('choose-area')" v-model="form.city" :options="cities"/>
            </div>
            <div class="col-md-3 filter-item" v-if="form.city && form.city === '1'">
              <Dropdown :label="$t('choose-university')" :nullable="true" v-model="form.university" :options="universities"/>
            </div>
            <div class=" col-md-3 filter-item">
              <InputField :label="$t('price-from')" v-model="form.priceFrom" :content="form.priceFrom"
                          :inputtype="'number'"/>
            </div>
            <div class=" col-md-3 filter-item">
              <InputField :label="$t('price-to')" v-model="form.priceTo" :content="form.priceTo"
                          :inputtype="'number'"/>
            </div>
          </div>
        </div>
        <div class="col-md-10 search-results-list row">
          <div class="alert alert-danger" v-if="!isLoading && (!posts || posts.length === 0)">
            {{ $t('roommate-ads-not-found') }}
          </div>
          <div style="min-height: 20rem" v-if="isLoading">
            <PreLoader/>
          </div>
          <div class=" single-ad-item" v-for="post in posts" v-if="!isLoading">
            <router-link :to="{name: 'SingleRoommateOffer', params: {id: post.id}}">
              <RoommateAd :adtype="post.isVip ? 'premium' : ''"
                          :title="post.title"
                          :name="post.name"
                          :city="post.city.name"
                          :location="post.district.name"
                          :university="post.university"
                          :date="post.createdAt"
                          :price="post.price"
                          :description="post.description"
                          :image="post.images.length > 0 ? post.images[0].src : null"
              />
            </router-link>
          </div>
        </div>
        <div class="col-md-10 d-flex justify-content-center mb-5" v-if="pages">
          <Pagination
            :size="'medium'"
            :pages="pages"
            :current="currentPage"
            :route="'SearchRoommateResult'"
            @pageChanged="handlePageChange"
          />
        </div>
      </div>
    </div>
  </div>

</template>
