import Button from "@/components/Common/Buttons/Button/Button";
import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import Pagination from "@/components/Components/Pagination/Pagination";
import InputField from "@/components/Common/Inputs/Input/Input";
import PreLoader from "@/components/Common/PreLoader";
import Footer from "@/components/Footer/Footer";

export default {
    name: 'SearchRoommateResult',
    components: {
        Button,
        RoommateAd,
        Dropdown,
        Pagination,
        InputField,
        PreLoader,
        Footer
    },
    data() {
        return {
            currentPage: 1,
            postsPerPage: 10,
            offset: 0,
            total: null,
            pages: null,

            cities: null,
            universities: null,
            posts: null,
            form: {
                city: null,
                priceFrom: null,
                priceTo: null,
                university: null,
            },
            formCoolOff: false, // To prevent multiple requests,
            isLoading: false,
        }
    },
    watch: {
        form: {
            handler: async function (newVal, oldVal) {
                if (this.formCoolOff) {
                    return;
                }

                let vm = this;
                vm.formCoolOff = true;
                setTimeout(async () => {
                    await vm.search();
                    vm.formCoolOff = false;
                }, 800);
            },
            deep: true
        }
    },
    methods: {
        async handlePageChange(page) {
            this.currentPage = parseInt(page);
            this.offset = (page - 1)  * this.postsPerPage;
            await this.search();
        },
        async search() {
            let vm = this;
            this.isLoading = true;

            this.cities = await this.$store.dispatch('location/getCitiesMapped');
            const universities = await this.$store.dispatch('roommate/getRoommateUniversities');
            this.universities = {};
            universities.forEach((item) => {
                vm.universities[this.$t(item.name)] = item.id;
            });

            const request = {
                offset: this.offset,
                limit: this.postsPerPage
            };

            if (this.form.city !== null) {
                request.city = this.form.city;
            }
            if (this.form.priceFrom !== null) {
                request.priceFrom = this.form.priceFrom;
            }
            if (this.form.priceTo !== null) {
                request.priceTo = this.form.priceTo;
            }
            if (this.form.university !== null) {
                request.university = this.form.university;
            }

            const posts = await this.$store.dispatch('roommate/getRoommatePosts', request);
            this.posts = posts.data;
            this.total = posts.total;
            this.pages = Math.ceil(this.total / this.postsPerPage);

            this.isLoading = false;
        }
    },
    async mounted() {
        this.resultContainerRef = this.$refs.resultContainer;
        await this.search();
    },
    computed: {
        user() {
            return this.$store.state.user.info;
        },
        modals() {
            return this.$store.state.utils.modals;
        },
        isUserAuthenticated() {
            return this.$store.state.user.authenticated;
        },
    },
}
